import * as endpoints from '~/api/endpoints'

export const state = () => {
  return {
    stats: [],
    products: [],
    hasFetchedStats: false,
    hasFetchedProducts: false,
  }
}

export const mutations = {
  setStats(state, stats) {
    state.stats = stats
  },
  setProducts(state, products) {
    state.products = products
  },
  setHasFetchedStats(state, hasFetchedStats) {
    state.hasFetchedStats = hasFetchedStats
  },
  setHasFetchedProducts(state, hasFetchedProducts) {
    state.hasFetchedProducts = hasFetchedProducts
  },
}

export const getters = {
  hasStats(state) {
    return state.stats.length > 0
  },

  hasProducts(state) {
    return state.products.length > 0
  },
}

export const actions = {
  async getStats({ commit, dispatch, state }) {
    if (!state.hasFetchedStats) {
      const stats = await this.$axios.$get(endpoints.getExploreStats)

      if (stats) {
        commit('setStats', stats)
        commit('setHasFetchedStats', true)
      }
    }
  },

  async getProducts({ commit, state }) {
    if (!state.hasFetchedProducts) {
      const baseUrl = endpoints.getListingSearchByCategoryId(33)

      const params = new URLSearchParams({
        OnlyShowBottlesForSale: true,
        PageIndex: 1,
        PageSize: 12,
      })

      const { collection } = await this.$axios
        .$get(`${baseUrl}?${params.toString()}`)
        .catch(() => {
          commit('setProducts', [])
          commit('setHasFetchedProducts', false)
        })

      if (Array.isArray(collection)) {
        commit('setProducts', collection)
        commit('setHasFetchedProducts', true)
      }
    }
  },
}
