import { render, staticRenderFns } from "./w-login-form.vue?vue&type=template&id=95e859ba&scoped=true"
import script from "./w-login-form.vue?vue&type=script&lang=js"
export * from "./w-login-form.vue?vue&type=script&lang=js"
import style0 from "./w-login-form.vue?vue&type=style&index=0&id=95e859ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e859ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WButton: require('/app/components/global/w-button/w-button.vue').default,WStack: require('/app/components/global/w-stack/w-stack.vue').default})
