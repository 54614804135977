
import { getMetaTags } from '~/utils/get-meta-tags'

export default {
  layout: 'default',

  props: {
    error: {
      type: Object,
      default: () => null,
    },
  },

  head() {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      title: 'Oops!',
      meta: getMetaTags(this.$config),
    }
  },
}
