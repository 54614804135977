export const maxToastItems = 3

let toastIdCounter = 1

export const state = () => {
  return {
    items: [],
    blocked: [],
  }
}

export const mutations = {
  updateToastItems(state, items) {
    state.items = items
  },
  updateBlocked(state, newBlocked) {
    state.blocked = newBlocked
  },
}

export const actions = {
  clear({ commit }) {
    commit('updateToastItems', [])
  },

  /*
    You can block toast messages when it does not make sense to show the toast in the current ui context.

    For example:
    You have an ui that says "your deposit was completed". Showing a toast that also says "deposit is completed" would be a bit redundant in this case. In other cases it would probably be helpful.
  */
  blockToast({ commit, state }, forEvent) {
    if (!state.blocked.includes(forEvent)) {
      commit('updateBlocked', [...state.blocked, forEvent])
    }
  },

  unblockToast({ commit, state }, forEvent) {
    commit(
      'updateBlocked',
      state.blocked.filter((blockedEvent) => blockedEvent !== forEvent),
    )
  },

  triggerToast({ commit, state, getters }, data) {
    const { event, ...messageData } = data

    if (getters.isBlocked(event)) {
      return null
    }

    const newId = toastIdCounter++

    const newItems = [
      ...state.items,
      {
        id: newId,
        date: new Date(),
        dismissable: true,
        ...messageData,
      },
    ]

    if (newItems.length > maxToastItems) {
      newItems.shift()
    }

    commit('updateToastItems', newItems)

    return newId
  },

  dismissToast({ commit, state }, id) {
    const newItems = state.items.filter((item) => item.id !== id)
    commit('updateToastItems', newItems)
  },
}

export const getters = {
  items(state) {
    return state.items
  },
  isBlocked(state) {
    return function (reason) {
      return state.blocked.includes(reason)
    }
  },
}
