
import * as endpoints from '~/api/endpoints'
import { createDataProvider } from '~/components/global/w-autosuggest/data-provider'
import createTransport from '~/utils/autosuggest-transport'

export default {
  data() {
    return {
      searchQueryObject: null,
    }
  },

  computed: {
    categoryId() {
      return this.$route.name === 'category-slug'
        ? parseInt(this.$route.params.slug)
        : null
    },
  },

  created() {
    this.autosuggestDataProvider = createDataProvider({
      transport: createTransport({
        endpoint: endpoints.getExploreCategorySuggest(0),
        $axios: this.$axios,
      }),
    })
  },

  methods: {
    onGlobalSearchChange(searchQueryObject, cause) {
      this.searchQueryObject = searchQueryObject

      if (cause === 'clickOption') {
        const { itemTypeName, suggestion } = searchQueryObject

        this.onGlobalSearchSubmit(suggestion, itemTypeName)
      }
    },
    onGlobalSearchSubmit(suggestion, itemTypeName = ['Bottle', 'Cask']) {
      this.$router.push({
        path: this.$nuxt.context.app.localePath('/search'),
        query: {
          queryKeyword: this.searchQueryObject?.suggestion || suggestion,
          ItemTypeName: this.searchQueryObject?.itemTypeName || itemTypeName,
        },
      })
    },
  },
}
