
import createTimeElapsedMonitor from '~/mixins/time-elapsed'
import WIconButton from '../w-icon-button/w-icon-button.vue'
import WStack from '../w-stack/w-stack.vue'
import WButton from '../w-button/w-button.vue'
import { toastTypes } from './toast-types'

export default {
  components: { WIconButton, WStack, WButton },
  mixins: [
    createTimeElapsedMonitor({
      stateName: 'dateObj',
    }),
  ],
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: toastTypes.INFO,
    },
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    date: {
      type: [String, Date],
      default: null,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    autoCloseDelay: {
      type: Number,
      default: null,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case toastTypes.ERROR:
          return require('~/assets/images/message-warning-icon.svg?inline')
        case toastTypes.SUCCESS:
          return require('~/assets/images/message-success-icon.svg?inline')
        case toastTypes.INFO:
        default:
          return require('~/assets/images/message-info-icon.svg?inline')
      }
    },
    dateObj() {
      if (typeof this.date === 'string') {
        return new Date(this.date)
      }
      return this.date
    },
  },
  watch: {
    autoCloseDelay() {
      this.updateAutoClose()
    },
  },
  mounted() {
    this.updateAutoClose()
  },
  destroyed() {
    clearTimeout(this.autoCloseTimeout)
  },
  methods: {
    updateAutoClose() {
      clearTimeout(this.autoCloseTimeout)
      if (this.autoCloseDelay !== null) {
        this.autoCloseTimeout = setTimeout(() => {
          this.$emit('dismiss')
        }, this.autoCloseDelay)
      }
    },
  },
}
