// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const linkFields = gql`
  fragment linkFields on LinkRecord {
    label
    targetBlank
    externalLink
    datoLink {
      ... on ArticleRecord {
        _modelApiKey
        slug
      }
      ... on LessonRecord {
        _modelApiKey
        slug
      }
      ... on PageRecord {
        slug
      }
      ... on SeoPageRecord {
        slug
      }
      ... on WorkshopRecord {
        _modelApiKey
        slug
      }
      ... on ArticlesPageRecord {
        slug
      }
      ... on LessonsPageRecord {
        slug
      }
      ... on WorkshopsPageRecord {
        slug
      }
    }
  }
`
