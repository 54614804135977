import { createQuerySyncerVuexPlugin } from '~/utils/query-syncer'

export const plugins = [createQuerySyncerVuexPlugin()]

export const actions = {
  async loadUserData({ dispatch }) {
    await Promise.all([dispatch('trader/loadTags')])

    dispatch('trader/loadKeyFigures')
  },
}
