import Vue from 'vue'
import Jed from '@tannin/compat'

const state = Vue.observable({
  i18nInstance: null,
})

const get = ({ context, msgid, plural, count, values }) => {
  const text = state.i18nInstance
    ? state.i18nInstance.dcnpgettext('messages', context, msgid, plural, count)
    : msgid

  return values && values.length ? Jed.sprintf(text, ...values) : text
}

export function setCatalog(catalog) {
  /* precompile plural forms function */
  state.i18nInstance = Object.freeze(new Jed(catalog))
}

export function gettext(msgid, ...values) {
  return get({ msgid, values })
}

export function ngettext(msgid, plural, count, ...values) {
  return get({ msgid, values, plural, count })
}

export function pgettext(context, msgid, ...values) {
  return get({ msgid, values, context })
}

export function npgettext(context, msgid, plural, count, ...values) {
  return get({ msgid, values, plural, count, context })
}
