import { render, staticRenderFns } from "./registration-message.vue?vue&type=template&id=3dc5e821"
import script from "./registration-message.vue?vue&type=script&lang=js"
export * from "./registration-message.vue?vue&type=script&lang=js"
import style0 from "./registration-message.vue?vue&type=style&index=0&id=3dc5e821&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WMessage: require('/app/components/global/w-message/w-message.vue').default})
