
export default {
  props: {
    link: {
      type: Boolean,
    },
    width: {
      type: Number,
      default: 166,
    },
    alt: {
      type: String,
      default: 'SWEX Logo',
    },
  },
  computed: {
    src() {
      return require('@/assets/images/logo.svg')
    },
    imgProps() {
      return {
        src: this.src,
        width: this.width,
        alt: this.alt,
      }
    },
  },
}
