
import WSpinner from '../w-spinner/w-spinner.vue'
export default {
  components: { WSpinner },
  props: {
    appearDelay: {
      type: Number,
      default: 400,
    },
    minHeight: {
      type: String,
      default: '',
    },
    inverse: {
      type: Boolean,
      default: false,
    },
  },
}
