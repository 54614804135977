
import { mapState, mapGetters, mapActions } from 'vuex'
import debounce from 'lodash.debounce'
import GlobalSearch from '~/components/site-nav/global-search.vue'
import NavigationList from '~/components/site-nav/header-navigation/navigation-list.vue'
import UserLogin from '~/components/site-nav/header-navigation/user-login.vue'
import HeaderActions from '~/components/site-nav/header-navigation/header-actions.vue'
import LanguageDropdown from '~/components/site-nav/header-navigation/language-dropdown.vue'
import { navigationQuery } from '~/gql/navigation.graphql'

export default {
  components: {
    NavigationList,
    UserLogin,
    HeaderActions,
    LanguageDropdown,
    GlobalSearch,
  },
  data() {
    return {
      isMobileMenuOpen: false,
      isSearchOpen: false,
      isLanguageSelectorOpen: false,
      activeItem: null,
      navigation: [],
      shadowVisible: false,
    }
  },
  async fetch() {
    await this.getTheSWEXContent()
  },

  computed: {
    ...mapState('amountVisibility', ['showAmounts']),
    ...mapGetters('auth', ['isLoggedIn', 'isInvestor', 'hasMultipleAccounts']),

    logoWidth() {
      return this.isLargeScreen ? 196 : 166
    },

    isLargeScreen() {
      return this.$matchesBreakpoint('l')
    },

    privacyIcon() {
      return this.showAmounts
        ? require('~/assets/images/eye.svg?inline')
        : require('~/assets/images/eye-off.svg?inline')
    },

    mobileNavigationIcon() {
      return this.isMobileMenuOpen
        ? require('~/assets/images/mobile-navigation-cross.svg?inline')
        : require('~/assets/images/mobile-navigation-hamburger.svg?inline')
    },

    showNavigation() {
      return this.isLargeScreen || this.isMobileMenuOpen
    },

    menuItems() {
      return this.navigation.map((item) => {
        if (item.list.length > 1) {
          const [title] = item.title
          return {
            label: title.title,
            icon: title.icon,
            items: item.list.map((childItem) => {
              return {
                label: childItem.link.label,
                icon: childItem.icon,
                href: this.$getLinkFromDato(childItem.link),
              }
            }),
          }
        } else {
          const [link] = item.list
          return {
            label: link.link.label,
            icon: link.icon,
            href: this.$getLinkFromDato(link.link),
          }
        }
      })
    },

    menuItemsUser() {
      const { localePath } = this.$nuxt.context.app
      const { $gettext } = this

      if (this.isInvestor && !this.hasMultipleAccounts && this.isLargeScreen) {
        return [
          {
            label: $gettext('Logout'),
            icon: 'logout',
            emit: 'logout',
          },
        ]
      }

      return [
        {
          label: $gettext('My SWEX'),
          items: [
            ...(!this.isInvestor
              ? [
                  {
                    label: $gettext('Portfolio'),
                    icon: 'portfolio',
                    href: localePath('/portfolio/current'),
                  },
                  {
                    label: $gettext('Orders'),
                    icon: 'orders',
                    href: localePath('orders'),
                  },
                  {
                    label: $gettext('Balance'),
                    icon: 'balance',
                    href: localePath('balance'),
                  },
                  {
                    label: $gettext('Profile'),
                    icon: 'account',
                    href: localePath('account'),
                  },
                ]
              : []),

            ...(this.hasMultipleAccounts
              ? [
                  {
                    label: $gettext('Switch portfolios'),
                    icon: 'switch-portfolios',
                    href: localePath('select-portfolio'),
                  },
                ]
              : []),

            ...(!this.isInvestor
              ? [
                  {
                    label: $gettext('Privacy'),
                    icon: this.showAmounts ? 'privacy-on' : 'privacy-off',
                    emit: 'toggleAmountVisibility',
                  },
                ]
              : []),

            ...(this.isLargeScreen
              ? [
                  {
                    label: $gettext('Logout'),
                    icon: 'logout',
                    emit: 'logout',
                    showBorder: true,
                  },
                ]
              : []),
          ],
        },
      ]
    },
  },

  watch: {
    $route() {
      this.clearActiveItem()
      this.closeMobileMenu()
      this.closeSearch()
      this.closeLanguageSelector()
    },
    '$i18n.locale': async function () {
      await this.getTheSWEXContent()
    },
    isLargeScreen(newValue) {
      if (newValue) {
        this.closeMobileMenu()
      }
    },

    isMobileMenuOpen(newValue) {
      document.body.classList.toggle('mobile-nav-open', newValue)
    },

    activeItem(newValue) {
      if (newValue) {
        document.body.addEventListener(
          'click',
          this.navigationClickOutsideHandler,
        )
      } else {
        document.body.removeEventListener(
          'click',
          this.navigationClickOutsideHandler,
        )
      }
    },
  },

  created() {
    const debouncedScroll = debounce(this.handleScroll, 100, {
      leading: true,
      trailing: true,
    })
    window.addEventListener('scroll', debouncedScroll)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('amountVisibility', ['toggleAmountVisibility']),

    async getTheSWEXContent() {
      const variables = {
        locale: this.$i18n.locale,
      }

      const { navigation } = await this.$graphql.default.request(
        navigationQuery,
        variables,
      )

      this.navigation = navigation.navigation
    },

    openMobileMenu() {
      this.isMobileMenuOpen = true
    },

    closeMobileMenu() {
      this.isMobileMenuOpen = false
    },

    toggleMobileMenu() {
      return this.isMobileMenuOpen
        ? this.closeMobileMenu()
        : this.openMobileMenu()
    },

    toggleSearch() {
      this.isSearchOpen ? this.closeSearch() : this.openSearch()
    },

    openSearch() {
      this.isSearchOpen = true
      this.clearActiveItem()
      this.closeLanguageSelector()
    },

    closeSearch() {
      this.isSearchOpen = false
    },

    toggleLanguageSelector() {
      this.isLanguageSelectorOpen
        ? this.closeLanguageSelector()
        : this.openLanguageSelector()
    },

    openLanguageSelector() {
      this.isLanguageSelectorOpen = true
      this.clearActiveItem()
      this.closeSearch()
    },

    closeLanguageSelector() {
      this.isLanguageSelectorOpen = false
    },

    setActiveItem(itemLabel) {
      this.activeItem = itemLabel
      this.closeSearch()
      this.closeLanguageSelector()
    },

    clearActiveItem() {
      this.activeItem = null
    },

    navigationClickOutsideHandler(e) {
      const clickedMenuItem = e.target.closest('.menu-item')

      // not clicked on a menu item, so clicked outside
      if (!clickedMenuItem) {
        this.clearActiveItem()
      }
    },

    handleScroll(e) {
      this.shadowVisible = window.scrollY !== 0
    },
  },
}
