import Vue from 'vue'
import assert from './assert'

/*
  Helper function to deal with the constant repetition of loading and error states for basic crud mutations.

  Use like this:

  data() {
    return {
      saveOrderState: createAsyncState()
    }
  },
  methods: {
    saveOrder: defineAsyncAction('saveOrderState', function () {
      // do call to api.
      // no need to handle loading and error states because it is already handled
    })
  }
*/

// Use a symbol because we don't want to expose setState
const setState = Symbol('setState')
const setError = Symbol('setError')

const asyncStateValues = {
  IDLE: 0,
  ERROR: 1,
  PENDING: 2,
  DONE: 3,
}

const isApiError = (e) => {
  return e.isAxiosError || !!e.response
}

export const errorCodes = {
  INVALID_REQUEST: 'invalid_request',
  TIMEOUT: 'timeout',
  SERVER: 'server',
  CLIENT_TRIGGERED: 'client_triggered',
}

export class AsyncError extends Error {
  constructor(
    message,
    code = errorCodes.CLIENT_TRIGGERED,
    originalResponse = null,
  ) {
    super(message)
    this.code = code
    this.originalResponse = originalResponse
  }

  get validationErrors() {
    return this.originalResponse?.data?.errors ?? {}
  }

  get timeout() {
    return this.code === errorCodes.TIMEOUT
  }

  get invalidRequest() {
    return this.code === errorCodes.INVALID_REQUEST
  }

  get server() {
    return this.code === errorCodes.SERVER
  }

  static fromAxiosError(e) {
    const status = e.response?.status
    const message = e.response?.data?.detail ?? ''

    if (status === 412) {
      return new AsyncError(message, errorCodes.INVALID_REQUEST, e.response)
    }

    if (status === 422) {
      return new AsyncError(message, errorCodes.INVALID_REQUEST, e.response)
    }

    if (status >= 400 && status < 500) {
      return new AsyncError(message, errorCodes.INVALID_REQUEST, e.response)
    }

    return new AsyncError(message, errorCodes.SERVER, e.response)
  }
}

export function createAsyncState() {
  const observable = Vue.observable({
    state: asyncStateValues.IDLE,
    errorInfo: null,
  })

  return Object.freeze({
    [setState](newState) {
      observable.state = newState
    },
    [setError](error) {
      observable.errorInfo = error ?? null
    },
    reset() {
      observable.state = asyncStateValues.IDLE
      observable.errorInfo = null
    },
    get error() {
      return observable.errorInfo
    },
    get pending() {
      return observable.state === asyncStateValues.PENDING
    },
    get done() {
      return observable.state === asyncStateValues.DONE
    },
    get idle() {
      return observable.state === asyncStateValues.IDLE
    },
  })
}

export function defineAsyncAction(stateName, handler) {
  return async function () {
    if (this[stateName].pending) {
      return
    }

    assert(
      stateName in this && this[stateName][setState],
      `Unable to find state with name ${stateName}. Make sure to make one using createAsyncData`,
    )

    this[stateName][setState](asyncStateValues.PENDING)
    this[stateName][setError](null)

    try {
      await handler.apply(this, arguments)
      this[stateName][setState](asyncStateValues.DONE)
    } catch (e) {
      this[stateName][setState](asyncStateValues.ERROR)

      const shouldThrowError = !isApiError(e) && !(e instanceof AsyncError)

      if (shouldThrowError) {
        // something went wrong in the client (however this might also be the api returning a unexpected response)
        // let's throw the error so that it will be reported to the error logging system
        throw e
      } else {
        /*
          api or Network error
          Network error can be anything (blocked by firewall, offline, bad internet)
        */
        let errorInfo = e
        if (!(errorInfo instanceof AsyncError)) {
          errorInfo = AsyncError.fromAxiosError(e)
        }

        this[stateName][setError](errorInfo)

        return errorInfo
      }
    }
  }
}
