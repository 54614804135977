export default ({ $axios, i18n, store }) => {
  $axios.onRequest(async (config) => {
    const language = i18n.localeProperties.iso

    const token = await store.dispatch('auth/getAccessToken')

    config.withCredentials = true
    config.headers.common['accept-language'] = language
    config.headers.common.Authorization = `Bearer ${token}`

    // async function returns promise by default
    // https://github.com/nuxt-community/axios-module/issues/482#issuecomment-815958751
    return config
  })
}
