export const dialogContainer = {
  create() {
    if (document && !document.getElementById('w-dialogs-container')) {
      const el = document.createElement('div')
      el.id = 'w-dialogs-container'
      document.body.appendChild(el)
    }
  },
  clickedOnBackdrop(event) {
    return event.target === document.getElementById('w-dialogs-container')
  },
  showBackdrop() {
    document
      .getElementById('w-dialogs-container')
      .classList.add('w-dialogs-container--with-backdrop')
  },
  hideBackdrop() {
    document
      .getElementById('w-dialogs-container')
      .classList.remove('w-dialogs-container--with-backdrop')
  },
}
