export default function createTransport({ endpoint, $axios }) {
  let cancelSource

  return {
    cancel() {
      cancelSource?.cancel()
    },
    get(searchText) {
      this.cancel()

      cancelSource = $axios.CancelToken.source()

      return $axios.$get(endpoint, {
        params: {
          s: searchText,
        },
        progress: false,
        cancelToken: cancelSource.token,
      })
    },
  }
}
