
import { mapGetters } from 'vuex'
import * as endpoints from '~/api/endpoints'
import { removeRouteNameLanguageSuffix } from '~/utils/remove-route-name-language-suffix'

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('auth', ['isLoggedIn']),

    availableLocales() {
      return this.$i18n.locales
    },

    // Some pages cannot be redirected by the @nuxtjs/i18n module
    // See the Readme chapter 'Routing Exceptions' for more information
    customRedirects() {
      return [
        {
          path: 'account-documents-slug',
          redirectTo: 'account-documents',
        },
        {
          path: 'orders-slug',
          redirectTo: 'orders',
        },
        {
          path: 'articles-slug',
          redirectTo: 'articles',
        },
        {
          path: 'lessons-slug',
          redirectTo: 'lessons',
        },
        {
          path: 'workshops-and-webinars-slug',
          redirectTo: 'workshops-and-webinars',
        },
        {
          path: 'slug',
          // Use 'whisky-catalogue' page as homepage when logged in
          redirectTo: this.isLoggedIn ? 'whisky-catalogue' : 'index',
        },
      ]
    },
  },
  methods: {
    async selectLanguage(locale) {
      if (locale === this.$i18n.locale) {
        this.$emit('close-dropdown')
        return
      }

      if (this.isLoggedIn) {
        await this.saveUserLanguage(locale)
      }

      this.$i18n.setLocale(locale)
      this.handleCustomRedirect(locale)
      // reload the webpage after the language is set
      if (this.isLoggedIn) {
        location.reload()
      }
    },

    async saveUserLanguage(locale) {
      await this.$axios.$put(
        `${endpoints.putTraderLanguage}?languageCode=${locale}`,
      )
    },

    handleCustomRedirect(locale) {
      const currentRouteName = removeRouteNameLanguageSuffix(this.$route.name)

      // Check if the current page has a custom redirect
      const customRedirect = this.customRedirects.find(
        (item) => item.path === currentRouteName,
      )

      if (customRedirect) {
        // Returns the localized URL for a given page
        const url = this.$nuxt.context.app.localePath(
          customRedirect.redirectTo,
          locale,
        )

        this.$router.push(url)
      }
    },
  },
}
