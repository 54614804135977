import assert from './assert'
import { EMPTY } from './optional-values'

const splitPath = (path) => {
  assert(typeof path === 'string', 'path should be a string')
  return path.split('.')
}

function getValueByPathParts(data, parts) {
  // use a simple for loop for performance
  let value = data
  for (let i = 0, l = parts.length; i < l; i++) {
    value = value[parts[i]]
    if (typeof value === 'undefined') {
      return EMPTY
    }
  }
  return value
}

/**
 * Caches the string split and is therefore an faster alternative to getValueByPath
 * @param {String} path
 * @returns {*)
 */
export function createGetValueByPathMemoized(path) {
  const parts = splitPath(path)
  return (data) => getValueByPathParts(data, parts)
}

/**
 * Extract value for the given (deep) property path. Example of valid paths:
 *
 * 'name'
 * 'user.name'
 *
 * Returns null when the value was not found
 *
 * @param {*} data
 * @param {String} path
 * @returns
 */
export function getValueByPath(data, path) {
  return getValueByPathParts(data, splitPath(path))
}
