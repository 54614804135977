import Vue from 'vue'

export function createDefaultTransport({
  endpoint,
  transform = (data) => data,
}) {
  let abortController

  return {
    cancel() {
      abortController?.abort()
    },
    get(searchText) {
      this.cancel()

      abortController = new AbortController()

      return fetch(endpoint + '?q=' + encodeURIComponent(searchText), {
        method: 'GET',
        signal: abortController.signal,
      })
        .then((res) => res.json())
        .then(transform)
        .catch(() => [])
    },
  }
}

export function createDataProvider({ transport }) {
  const data = Vue.observable({ results: [], state: 'idle' })

  function dedup(results) {
    return Array.from(new Set(results))
  }

  return Object.freeze({
    get state() {
      return data.state
    },

    get results() {
      return data.results
    },

    clear() {
      transport.cancel()
      data.results = []
      data.state = 'idle'
    },

    async request(searchText) {
      if (data.state === 'pending') {
        transport.cancel()
      }

      data.state = 'pending'
      try {
        data.results = dedup(await transport.get(searchText))
      } catch (e) {
        data.results = []
      }
      data.state = 'done'
    },
  })
}
