import Vue from 'vue'
import assert from '../utils/assert'
import { setCatalog } from './catalog'
import { updateFormatters } from './formatters'

const currentLocale = Vue.observable({
  value: 'en',
})

export function setLabelsLocale(locale, catalog) {
  assert(catalog, 'Please supply a valid catalog')
  currentLocale.value = locale
  setCatalog(catalog)
  updateFormatters(locale)
}
