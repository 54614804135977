export default ({ i18n, app }, inject) => {
  inject('getLinkFromDato', (link) => {
    if (!link) {
      return null
    }

    const { datoLink, externalLink, targetBlank } = link

    if (datoLink?.slug) {
      if (datoLink?._modelApiKey) {
        const modelApiMap = {
          article: 'articles',
          lesson: 'lessons',
          workshop: 'workshops-and-webinars',
        }

        const modelApiKey = datoLink._modelApiKey

        return app.localePath(`/${modelApiMap[modelApiKey]}/${datoLink.slug}`)
      }

      return `/${i18n.locale}/${datoLink?.slug}`
    }

    if (externalLink) {
      return targetBlank ? externalLink : `/${i18n.locale}${externalLink}`
    }
  })
}
