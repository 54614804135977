
import { StructuredText } from 'vue-datocms'
import { footerQuery } from '~/gql/footer.graphql'
import MailLineIcon from '~/assets/images/mail-line-icon.svg?inline'
import PhoneLineIcon from '~/assets/images/phone-line-icon.svg?inline'
import FacebookFillIcon from '~/assets/images/facebook-fill-icon.svg?inline'
import LinkedInFillIcon from '~/assets/images/linkedin-fill-icon.svg?inline'
import InstagramFillIcon from '~/assets/images/instagram-fill-icon.svg?inline'
import VimeoFillIcon from '~/assets/images/vimeo-fill-icon.svg?inline'

export default {
  components: {
    'datocms-structured-text': StructuredText,
    MailLineIcon,
    PhoneLineIcon,
    FacebookFillIcon,
    LinkedInFillIcon,
    InstagramFillIcon,
    VimeoFillIcon,
  },

  data() {
    return {
      contactTitle: '',
      contactLinks: [],
      address: {},
      socials: [],
      disclaimer: [],
      navigation: [],
    }
  },

  async fetch() {
    await this.getFooterContent()
  },

  computed: {
    showFooter() {
      return !this.$fetchState.pending && !this.$fetchState.error
    },

    hasContactLinks() {
      return this.contactLinks.length > 0
    },

    hasSocials() {
      return this.socials.length > 0
    },

    hasDisclaimer() {
      return this.disclaimer.length > 0
    },

    disclaimerContent() {
      return this.disclaimer[0]?.richText
    },
  },

  watch: {
    '$i18n.locale': async function () {
      await this.getFooterContent()
    },
  },

  methods: {
    getLinkIcon(url) {
      const patterns = {
        PhoneLineIcon: /tel:/i,
        MailLineIcon: /mailto:/i,
        FacebookFillIcon: /facebook/i,
        LinkedInFillIcon: /linkedin/i,
        VimeoFillIcon: /vimeo/i,
        InstagramFillIcon: /instagram/i,
      }

      const matchingIcon = Object.keys(patterns).find((icon) =>
        patterns[icon].test(url),
      )

      return matchingIcon || null
    },

    linkGoesOutside(link) {
      return link.targetBlank
    },

    async getFooterContent() {
      const variables = {
        locale: this.$i18n.locale,
      }

      const { footer } = await this.$graphql.default.request(
        footerQuery,
        variables,
      )

      this.contactTitle = footer.contactTitle
      this.contactLinks = footer.contactLinks
      this.address = footer.address[0]
      this.socials = footer.socials
      this.disclaimer = footer.disclaimer
      this.navigation = footer.menu
    },
  },
}
