import Vue from 'vue'
import assert from '~/utils/assert'

export default function ({ $config }, inject) {
  const signalClientLoadPromise = import(
    /* webpackPrefetch: true, webpackChunkName: 'signalr' */ '@microsoft/signalr'
  ).then((client) => {
    let builder = new client.HubConnectionBuilder()
      .withUrl($config.eventHubBaseURL)
      .withAutomaticReconnect()

    if (process.env.NODE_ENV !== 'production') {
      builder = builder.configureLogging(client.LogLevel.Information)
    }

    return builder.build()
  })

  const callbacksByEventName = new Map()

  function triggerEvent(eventName, event) {
    if (!callbacksByEventName.has(eventName)) {
      return
    }
    const listeners = callbacksByEventName.get(eventName)
    listeners.forEach((listener) => {
      listener(event)
    })
  }

  function addEventListener(eventType, cb) {
    assert(typeof eventType === 'string', 'Expect eventType to be an string')
    assert(typeof cb === 'function', 'Expect callback to be an function')

    if (!callbacksByEventName.has(eventType)) {
      callbacksByEventName.set(eventType, new Set())
    }
    callbacksByEventName.get(eventType).add(cb)

    return () => {
      callbacksByEventName.get(eventType)?.delete(cb)
    }
  }

  /*
    This plugin adds per-ui-component instance event listeners. These listeners will only live as long as the component in which they are used.
  */
  Vue.use({
    install() {
      Vue.mixin({
        created() {
          this.eventUnsubscribers = new Set()
        },
        destroyed() {
          this.eventUnsubscribers.forEach((unsubscribe) => {
            unsubscribe()
          })
        },
        methods: {
          $onSignalREvent(eventType, cb) {
            const unsubscribe = addEventListener(eventType, cb)
            this.eventUnsubscribers.add(unsubscribe)
            return () => {
              unsubscribe()
              this.eventUnsubscribers.delete(unsubscribe)
            }
          },
        },
      })
    },
  })

  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    window.triggerSignalREvent = triggerEvent
  }

  // i'm using a Vue instance as a event bus.
  const events = new Vue()

  /*
    Global notification controller. Can be accessed through nuxt context
  */
  inject('signalR', {
    on: events.$on.bind(events),
    off: events.$off.bind(events),

    async open() {
      const client = await signalClientLoadPromise

      if (client.state !== 'Disconnected') {
        return
      }

      client.start()

      client.on('HandleEvent', triggerEvent)

      events.$emit('open')
    },

    async close() {
      callbacksByEventName.clear()
      const client = await signalClientLoadPromise
      client.stop()

      events.$emit('close')
    },

    onEvent(eventType, cb) {
      return addEventListener(eventType, cb)
    },
  })
}
