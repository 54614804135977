/* make sure that we don't crash when sessionStorage is not available (due to private mode for example) */
export const safeSessionStorage = {
  removeItem(...args) {
    try {
      window.sessionStorage.removeItem(...args)
    } catch (e) {}
  },
  setItem(...args) {
    try {
      window.sessionStorage.setItem(...args)
    } catch (e) {}
  },
  getItem(name) {
    return window.sessionStorage?.getItem(name)
  },
}

export const safeLocalStorage = {
  removeItem(...args) {
    try {
      window.localStorage.removeItem(...args)
    } catch (e) {}
  },
  setItem(...args) {
    try {
      window.localStorage.setItem(...args)
    } catch (e) {}
  },
  getItem(name) {
    return window.localStorage?.getItem(name)
  },
}
