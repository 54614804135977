
import WAutosuggestFullscreen from './w-autosuggest-fullscreen.vue'
import WAutosuggestPopup from './w-autosuggest-popup.vue'
import { props as sharedProps } from './autosuggest-shared'

export default {
  components: { WAutosuggestPopup, WAutosuggestFullscreen },
  props: {
    ...sharedProps,

    mobileFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSmallScreen() {
      return this.$matchesBreakpoint('sm')
    },
    canGoFullScreen() {
      return this.mobileFullScreen && this.isSmallScreen
    },
  },
  created() {
    this.eventProxy = {
      input: this.$emit.bind(this, 'input'),
      change: this.$emit.bind(this, 'change'),
      'fullscreen-change': this.$emit.bind(this, 'fullscreen-change'),
    }
  },
  render(h) {
    return h(
      this.canGoFullScreen ? WAutosuggestFullscreen : WAutosuggestPopup,
      {
        props: this.$props,
        attrs: this.$attrs,
        on: this.eventProxy,
        scopedSlots: this.$scopedSlots,
      },
    )
  },
}
