
export const themeableProps = {
  icon: {
    type: Object,
    default: null,
  },
  iconOnly: {
    type: Boolean,
    default: false,
  },
  iconAlignment: {
    type: String,
    default: 'left',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  autoWidth: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'primary',
  },
  size: {
    type: [String, Object],
    default: 'medium',
  },
}

export default {
  props: {
    ...themeableProps,
    component: {
      type: [String, Object],
      default: 'span',
    },
    siblingToField: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClasses() {
      return typeof this.size === 'object'
        ? Object.entries(this.size).map(
            ([breakpoint, size]) => `w-button-look--size-${size}-${breakpoint}`,
          )
        : [`w-button-look--size-${this.size}`]
    },
  },
}
