/* eslint-disable camelcase */
import * as endpoints from '~/api/endpoints'
import { safeLocalStorage } from '~/utils/safe-storage'

export const state = () => {
  return {
    session: '',
    languageCode: 'en',
  }
}

export const mutations = {
  setSession(state, session) {
    state.session = session
  },
  setLanguageCode(state, languageCode) {
    state.languageCode = languageCode
  },
}

export const getters = {
  accountCount(state) {
    return state.session ? state.session.relatedAccounts.length : 0
  },

  hasMultipleAccounts(_state, getters) {
    return getters.accountCount ? getters.accountCount > 1 : false
  },

  isLoggedIn(state) {
    return !!(state.session && state.session.traderId !== null)
  },

  isInvestor(state) {
    return state.session ? state.session.accountType === 'Investor' : false
  },
}

export const actions = {
  async getAccessToken({ dispatch }) {
    const idpStoredUser = await dispatch('getIdpStoredUser')

    if (!idpStoredUser) {
      return null
    }

    return idpStoredUser.access_token
  },

  async hasValidIdpUser({ dispatch }) {
    const now = Date.now()
    const user = await dispatch('getIdpStoredUser')

    if (!user) {
      return false
    }

    // expires_at is in seconds
    const { expires_at } = user

    return expires_at ? expires_at * 1000 > now : false
  },

  async getIdpStoredUser() {
    const { identityServerUrl } = this.$config

    const userObject = await this.$idpUserStorage.get(
      `user:${identityServerUrl}:spa`,
    )

    if (!userObject) {
      return null
    }

    const user = JSON.parse(userObject)

    return user
  },

  async checkHasSession({ dispatch, getters }) {
    if (getters.isLoggedIn) {
      return
    }

    try {
      await this.$axios.$head(endpoints.getSession)

      await dispatch('loadSessionInformation')
    } catch (e) {
      // fail silently
    }
  },

  async loadSessionInformation({ commit, dispatch }) {
    const response = await this.$axios.$get(endpoints.getSession)

    commit('setSession', response)
    commit('setLanguageCode', response.languageCode)

    const currentLocale = this.$i18n.locale

    if (response.languageCode !== currentLocale) {
      await this.$i18n.setLocale(response.languageCode)
    }

    await dispatch('loadUserData', null, { root: true })

    this.$signalR.open()
  },

  async updateSessionInformation({ commit }) {
    const response = await this.$axios.$get(endpoints.getSession)

    commit('setSession', response)
  },

  selectPortfolio({ dispatch }, signinId) {
    safeLocalStorage.setItem('switchedPortfolio', true)

    dispatch('idpSigninRedirect', signinId)
  },

  async logout({ commit, dispatch }, payload) {
    const { redirect = true, removeToken = false } = payload || {}

    await commit('setSession', '')
    await dispatch('trader/clearUserData')

    safeLocalStorage.removeItem('switchedPortfolio')
    await this.$signalR.close()

    if (redirect) {
      await this.$idpUserManager.signoutRedirect()
    }

    if (removeToken) {
      await this.$idpUserManager.removeUser()
    }
  },

  /**
   * https://authts.github.io/oidc-client-ts/classes/UserManager.html#signinRedirect
   * used to redirect to the identity server for login
   *
   * Pass a `signinId` to sign in as that user. Only after already logging in
   */
  idpSigninRedirect(_store, signinId) {
    const extraQueryParams = signinId ? { loginAs: signinId } : undefined

    return this.$idpUserManager.signinRedirect({
      extraQueryParams,
    })
  },

  // https://authts.github.io/oidc-client-ts/classes/UserManager.html#signinCallback
  async idpSigninCallback() {
    await this.$idpUserManager.signinCallback()
  },
}
