import assert from './assert'
import { getValueByPath } from './get-value-by-path'

const SEP = '\u0000'

export default function createObjectHash(data, keys) {
  assert(Array.isArray(keys), 'Keys should be an array')
  let hash = ''
  for (let i = 0; i < keys.length; i++) {
    const value = getValueByPath(data, keys[i])
    if (!value) {
      continue
    }
    if (hash !== '') {
      hash += SEP
    }
    hash += value
  }
  return hash
}
