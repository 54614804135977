// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { linkFields } from './elements/linkFragment.graphql'

export const navigationQuery = gql`
  query Navigation($locale: SiteLocale) {
    navigation(locale: $locale) {
      navigation {
        title {
          title
          icon
        }
        list {
          link {
            ...linkFields
          }
          icon
        }
      }
    }
  }
  ${linkFields}
`
