import { removeRouteNameLanguageSuffix } from '~/utils/remove-route-name-language-suffix'

const PUBLIC_PAGES = ['whisky-basket']

export default async ({ store, route, redirect, app, error }) => {
  // TODO: logic regarding public pages should be removed as soon as the migration to Nuxt 3
  // Pages that require authentication or other properties that affects middleware
  // should use definePageMeta macro which is currently not available
  // Furthermore, it's best to use named middleware specifically for pages that require auth
  const isPublicPage =
    route.meta.some((item) => item.isPublic) ||
    PUBLIC_PAGES.some((page) => route.name.startsWith(page))

  // nuxt gives all routes defined in the 'pages' folder a route name
  if (!route.name) {
    return error({
      statusCode: 404,
      message: "Route doesn't exist",
    })
  }

  // make sure user info is always available when logged in
  if (!store.getters['auth/isLoggedIn']) {
    await store.dispatch('auth/checkHasSession')
  }

  // no need to be logged in here
  if (isPublicPage) {
    return true
  }

  // if user is not logged in, redirect to login page
  if (!store.getters['auth/isLoggedIn']) {
    const localizedRedirectURL = app.localePath('login')
    await redirect(localizedRedirectURL)
    return false
  }

  const routeName = removeRouteNameLanguageSuffix(route.name)

  if (
    routeName !== 'investor-portfolio' &&
    routeName !== 'select-portfolio' &&
    store.getters['auth/isInvestor']
  ) {
    await redirect(app.localePath('investor-portfolio'))
    return false
  }

  return true
}
