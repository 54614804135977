
import LoginDialog from '~/components/login-dialog/login-dialog.vue'

export default {
  components: {
    LoginDialog,
  },

  data() {
    return {
      isDialogOpen: false,
    }
  },
  computed: {
    isLargeScreen() {
      return this.$matchesBreakpoint('l')
    },

    variant() {
      return this.isLargeScreen ? 'secondary' : 'primary'
    },
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true
    },

    closeDialog() {
      this.isDialogOpen = false
    },
  },
}
