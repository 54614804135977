// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const imageFields = gql`
  fragment imageFields on FileField {
    responsiveImage(imgixParams: { fit: crop, w: 650, auto: format }) {
      src
      alt
      width
      height
      sizes
      title
    }
  }
`
