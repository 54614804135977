
import { createFocusTrap } from 'focus-trap'
import RemoveIcon from '~/assets/images/remove-icon.svg?inline'
import { dialogController } from '~/plugins/components/dialogs'
import WPanel from '../w-panel/w-panel'
import WStack from '../w-stack/w-stack'

export default {
  components: {
    WPanel,
    RemoveIcon,
    WStack,
  },
  inject: ['dialogId'],
  props: {
    title: {
      type: String,
      default: null,
    },
    form: {
      type: Boolean,
      default: false,
    },
    formNovalidate: {
      type: Boolean,
      default: false,
    },
    buttonAlignment: {
      type: String,
      default: 'right',
    },
    hasCloseIcon: {
      type: Boolean,
      default: true,
    },
    onScroll: {
      type: Function,
      default: () => {},
    },
    noEscape: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasFooter() {
      return this.$slots.footer || this.$scopedSlots.footer
    },
  },
  mounted() {
    this.createOrGetTrap().activate()
  },
  destroyed() {
    this.createOrGetTrap().deactivate()
  },
  methods: {
    triggerCloseIntent() {
      if (!this.noEscape) {
        dialogController.close()
      }
    },
    createOrGetTrap() {
      if (this.trap) {
        return this.trap
      }
      const dialogElement = document.getElementById(this.dialogId)
      this.trap = createFocusTrap(dialogElement, {
        delayInitialFocus: true,
        fallbackFocus: dialogElement,
        escapeDeactivates: this.noEscape,
        clickOutsideDeactivates: this.noEscape,
      })
      return this.trap
    },
  },
}
