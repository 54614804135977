import debounce from 'lodash.debounce'
import componentInstanceId from '~/mixins/component-instance-id'

export const props = {
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  minCharacters: {
    type: Number,
    default: 3,
  },
  dataProvider: {
    type: Object,
    required: true,
  },
  debounceTime: {
    type: Number,
    default: 500,
  },
  noResultsText: {
    type: String,
    default: null,
  },
}

export const logic = {
  mixins: [componentInstanceId('autosuggest')],
  data() {
    return {
      focused: false,
      text: this.value,
      activeOptionId: null,
    }
  },
  computed: {
    $loading() {
      return this.dataProvider.state === 'pending'
    },
    $canUseOptions() {
      return this.$canDoRequest && this.$optionItems.length && this.focused
    },
    $canDoRequest() {
      return this.text.length >= this.minCharacters
    },
    $optionItems() {
      return this.dataProvider.results.map((item, index) => ({
        ...item,
        id: `autosuggest-item-${index}`,
        label: item.suggestion,
      }))
    },
    $inputProps() {
      return {
        ...this.$attrs,
        autocomplete: 'off',
        'aria-autocomplete': 'list',
        autocapitalize: 'false',
        spellcheck: 'false',
        'aria-owns': this.$id,
        'aria-haspopup': 'true',
        'aria-activedescendant': this.activeOptionId,
        role: 'combobox',
        placeholder: this.placeholder,
        value: this.text,
      }
    },
    $inputEvents() {
      return {
        input: this.$onInput,
        focus: this.$onFocus,
        blur: this.$onBlur,
        change: this.$onChange,
        keydown: this.$onPressKey,
      }
    },
    $optionListProps() {
      return {
        id: this.$id,
        ref: 'options',
        options: this.$optionItems,
      }
    },
    $optionListEvents() {
      return {
        'click-option': this.$onClickOption,
        'change-active': this.$onChangeActive,
      }
    },
  },
  watch: {
    debounceTime() {
      this.$_createDebouncedTriggerRequest()
    },
    value(newValue) {
      if (newValue !== this.text) {
        this.text = newValue
        this.$triggerInput()
      }
    },
  },
  created() {
    this.$_createDebouncedTriggerRequest()
  },
  destroyed() {
    this.dataProvider.clear()
  },
  methods: {
    $_createDebouncedTriggerRequest() {
      this.$_triggerRequestDebounced = debounce(
        this.$triggerRequest,
        this.debounceTime,
      )
    },

    $triggerInput() {
      this.$moveVisualFocusToInput()

      if (this.$canDoRequest && this.focused) {
        this.$_triggerRequestDebounced()
      } else {
        this.$clear()
      }
    },

    $triggerRequest() {
      this.dataProvider.request(this.text)
    },

    $clear() {
      this.dataProvider.clear()
    },

    $onInput(e) {
      this.text = e.target.value
      this.$emit('input', this.text)

      this.$triggerInput()
    },

    $onChange() {
      const text = this.text

      /*
        to prevent change from triggering twice (once when clicking on a option and once during traditional change event),
        we wait a frame to see if the change was not caused by a click
      */
      this.$nextTick(() => {
        if (text === this.text) {
          const selectedOption = this.$optionItems.find(
            (entry) => entry.suggestion === this.text,
          )

          this.$emit('change', selectedOption, 'defocus')
        }
      })
    },

    $onBlur() {
      this.focused = false
    },

    $onFocus() {
      this.focused = true

      this.$moveVisualFocusToInput()

      if (!this.$canUseOptions) {
        if (this.$canDoRequest) {
          this.$triggerRequest()
        } else {
          this.$clear()
        }
      }

      this.$emit('focus')
    },

    $onPressKey(e) {
      e.stopPropagation()

      switch (e.key) {
        /* because we are typing in the textfield we need to make sure that space does not trigger a select in the option-list */
        case ' ': {
          return
        }

        case 'ArrowDown':
        case 'ArrowUp': {
          e.preventDefault()

          if (!this.$canUseOptions) {
            return
          }

          break
        }

        /* per aria spec. arrow left and rights keys should move visual focus to input */
        case 'ArrowLeft':
        case 'ArrowRight': {
          this.$moveVisualFocusToInput()
          return
        }
      }

      this.$redirectKeyboardEventToOptions(e)
    },

    $onClickOption(optionId) {
      const selectedOption = this.$optionItems.find(
        (entry) => entry.id === optionId,
      )

      this.text = selectedOption.suggestion
      this.$emit('input', this.text)

      this.$emit('change', selectedOption, 'clickOption')
    },

    $onChangeActive({ optionId }) {
      if (optionId) {
        this.activeOptionId = optionId
      }
    },

    $redirectKeyboardEventToOptions(e) {
      this.$refs.options.$el.dispatchEvent(new KeyboardEvent(e.type, e))
    },

    $moveVisualFocusToOptions() {
      this.$refs.options.$el.dispatchEvent(new FocusEvent('focus'))
    },

    $moveVisualFocusToInput() {
      this.$refs.options.$el.dispatchEvent(
        new FocusEvent('focusout', { relatedTarget: document.body }),
      )
    },
  },
}
