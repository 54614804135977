const getMetaTags = (nuxtConfig) => {
  const meta = [
    {
      hid: 'environment',
      name: 'environment',
      content: nuxtConfig.environment,
    },
  ]

  if (nuxtConfig.environment !== 'production') {
    meta.push({
      hid: 'robots',
      name: 'robots',
      content: 'noindex, nofollow',
    })
  }

  return meta
}

export { getMetaTags }
