
import * as focusTrap from 'focus-trap'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.openDialog()

    this.trap = focusTrap.createFocusTrap(this.$el, {
      clickOutsideDeactivates: true,
    })
  },

  beforeDestroy() {
    this.trap.deactivate()
  },

  methods: {
    openDialog() {
      this.$el.showModal()
    },

    onClose() {
      this.$store.dispatch('infoDialog/close')
    },

    closeDialog() {
      this.$el.close()
    },
  },
}
