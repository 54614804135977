const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24

export default function createTimeElapsedMonitor({ stateName }) {
  return {
    data() {
      return {
        secondsElapsed: 0,
      }
    },

    methods: {
      $__updateTimeExpired(now) {
        this.secondsElapsed = Math.max(
          (now - this[stateName].getTime()) / 1e3,
          0,
        )
      },
      $__startTimer() {
        this.$__stopTimer()
        let lastUpdate = 0
        const update = () => {
          const now = Date.now()
          this.$__updateTimeExpired(now)
          const correction = lastUpdate !== 0 ? now - lastUpdate - 60000 : 0
          lastUpdate = now
          this.$__timerTimeout = setTimeout(update, 60000 - correction)
        }
        update()
      },
      $__stopTimer() {
        clearInterval(this.$__timerTimeout)
        this.$__timerTimeout = 0
        this.secondsElapsed = 0
      },
    },

    mounted() {
      if (this[stateName]) {
        this.$__startTimer()
      }
    },

    watch: {
      [stateName](newValue) {
        if (newValue) {
          this.$__startTimer()
        } else {
          this.$__stopTimer()
        }
      },
    },

    destroyed() {
      this.$__stopTimer()
    },

    computed: {
      $elapsedText() {
        const secondsElapsed = this.secondsElapsed

        if (secondsElapsed > SECONDS_IN_DAY) {
          const days = Math.floor(secondsElapsed / SECONDS_IN_DAY)
          return this.$ngettext('%s day ago', '%s days ago', days, days)
        }

        if (secondsElapsed > SECONDS_IN_HOUR) {
          const hours = Math.floor(secondsElapsed / SECONDS_IN_HOUR)
          return this.$ngettext('%s hour ago', '%s hours ago', hours, hours)
        }

        if (secondsElapsed > SECONDS_IN_MINUTE) {
          const minutes = Math.floor(secondsElapsed / SECONDS_IN_MINUTE)
          return this.$ngettext(
            '%s minute ago',
            '%s minutes ago',
            minutes,
            minutes,
          )
        }

        return this.$gettext('just now')
      },
    },
  }
}
