
import WButtonLook, { themeableProps } from '../w-button-look/w-button-look'
import WSpinner from '../w-spinner/w-spinner.vue'

const buttonSpinner = {
  render(h) {
    return h(WSpinner, {
      class: 'w-button__spinner',
      props: {
        appearDelay: 200,
      },
    })
  },
}

const themeablePropKeys = Object.keys(themeableProps)

export default {
  components: {
    WButtonLook,
  },
  props: {
    ...themeableProps,
    href: {
      type: String,
      default: null,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    themeablePropValues() {
      const entries = Object.keys(themeableProps).map((key) => [key, this[key]])
      return Object.fromEntries(entries)
    },

    conditionalProps() {
      if (this.component === 'nuxt-link') {
        return { to: this.href }
      }

      if (this.component === 'a') {
        return {
          href: this.href,
          target: '_blank',
          rel: 'noreferer noopener',
        }
      }

      return {}
    },
    iconProps() {
      if (this.loading) {
        return {
          icon: buttonSpinner,
          iconAlignment: 'left',
        }
      }

      return {
        icon: this.icon,
        iconAlignment: this.iconAlignment,
      }
    },

    buttonType() {
      if (this.component === 'button') {
        return this.submit ? 'submit' : 'button'
      }
      return undefined
    },

    component() {
      if (this.href) {
        return this.href.match(/^(https?)?:\/\//) ? 'a' : 'nuxt-link'
      }

      return 'button'
    },
  },

  created() {
    this.themeablePropKeys = themeablePropKeys
  },
  methods: {
    onClick(event) {
      if (!this.loading) {
        this.$emit('click', event)
      }
    },
  },
}
