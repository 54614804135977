export const state = () => {
  return {
    isOpen: false,
    title: null,
    componentName: null,
    componentProps: null,
  }
}

export const mutations = {
  open(state, { title, componentName, componentProps }) {
    state.isOpen = true

    state.title = title

    state.componentName = componentName
    state.componentProps = componentProps
  },

  reset(state) {
    state.isOpen = false
    state.title = null
    state.componentName = null
    state.componentProps = null
  },
}

export const actions = {
  showTextContent({ dispatch }, { title, text }) {
    dispatch('open', {
      title,
      componentName: 'w-info-dialog-text-content',
      componentProps: { text },
    })
  },

  open({ commit }, { title, componentName, componentProps }) {
    commit('open', {
      title,
      componentName,
      componentProps,
    })
  },

  close({ commit }) {
    commit('reset')
  },
}
