
import WDialogLayout from '~/components/global/w-dialog/w-dialog-layout'
import * as endpoints from '~/api/endpoints'
import ApiErrorMessage from '../api-error-message/api-error-message.vue'

export default {
  components: {
    WDialogLayout,
    ApiErrorMessage,
  },
  data() {
    return {
      loading: false,
    }
  },
  async fetch() {
    this.offer = await this.$axios.$get(endpoints.getTraderOffer)
  },
  computed: {
    offerHTML() {
      const offerType =
        this.offer.offeringType === 'CaskFractions'
          ? 'Cask fraction offer'
          : 'Extending for offer'

      const fields = {
        brandName: this.offer.articleDetails.brandName,
        caskNumber: this.offer.articleDetails.caskNumber,
        yearDistillation: this.offer.articleDetails.yearDistillation,
        expectedMaturationAge: this.offer.articleDetails.expectedMaturationAge,
        valuationPrice: this.$formatAsCurrency(this.offer.valuationPrice),
        offeringPrice: this.$formatAsCurrency(this.offer.offeringPrice),
        dateOfferingEnd: this.$formatAsDate(this.offer.dateOfferingEnd),
      }

      let rawHtml = this.$gettext(offerType)
      for (const [key, value] of Object.entries(fields)) {
        rawHtml = rawHtml.replace(`%(${key})`, value)
        rawHtml = rawHtml.replace(`%{${key}}`, value)
      }

      return rawHtml
    },
  },
  methods: {
    async acceptOffer() {
      this.loading = true

      await this.$axios.put(endpoints.setTraderOffer, {
        offeringId: this.offer.offeringId,
        IsAccepted: true,
      })
      this.loading = false
      this.$dialogs.close()
    },
    async rejectOffer() {
      this.loading = true

      await this.$axios.put(endpoints.setTraderOffer, {
        offeringId: this.offer.offeringId,
        IsAccepted: false,
      })
      this.loading = false
      this.$dialogs.close()
    },
  },
}
