import Vue from 'vue'

import { customMedia } from '~/components/global/_core/breakpoints'

const mediaQueriesByBreakpoint = Object.entries(customMedia).reduce(
  (acc, [key, query]) => {
    return {
      ...acc,
      [key.replace(/^--m-/, '')]: query,
    }
  },
  {},
)

const ssr = typeof window === 'undefined'

const breakpointsMatched = Vue.observable(
  Object.keys(mediaQueriesByBreakpoint).reduce((acc, key) => {
    return {
      ...acc,
      [key]: ssr,
    }
  }, {}),
)

if (!ssr) {
  Object.entries(mediaQueriesByBreakpoint).forEach(([key, value]) => {
    const matcher = window.matchMedia(value)
    matcher.addListener((e) => {
      breakpointsMatched[key] = e.matches
    })
    breakpointsMatched[key] = matcher.matches
  })
}

if (!Vue.__matches_breakpoint__) {
  Vue.mixin({
    methods: {
      $matchesBreakpoint(breakpoint) {
        return breakpointsMatched[breakpoint]
      },
      $getMediaQuery(breakpoint) {
        return mediaQueriesByBreakpoint[breakpoint]
      },
    },
  })

  Vue.__matches_breakpoint__ = true
}
