import { toastTypes } from '~/components/global/w-toast-list/toast-types'
import { gettext } from '~/i18n/catalog'

export default ({ store, app }) => {
  let toastId = null

  window.addEventListener('offline', async () => {
    toastId = await store.dispatch('toasts/triggerToast', {
      title: gettext('No network'),
      message: gettext('Connect to the internet to keep using The SWEX'),
      type: toastTypes.ERROR,
      dismissable: true,
    })
  })

  window.addEventListener('online', () => {
    if (toastId) {
      store.dispatch('toasts/dismissToast', toastId)
    }
  })
}
