
export default {
  props: {
    variant: {
      type: String,
      default: null,
      validator: (value) => ['light'].includes(value.toLowerCase()),
    },
  },
}
