/* add support for the amazing Freshworks widget (kuch) */
import { gettext } from '~/i18n/catalog'

function createFreshWorksWidgetController() {
  const n = function () {
    n.q.push(arguments)
  }
  n.q = []
  window.FreshworksWidget = n
  window.FreshworksWidget('hide', 'launcher')

  const privateApi = {
    init({ widgetId, locale }) {
      if (typeof window.fwSettings !== 'undefined') {
        this.revive()
        return
      }

      window.fwSettings = {
        widget_id: widgetId,
        locale,
      }

      // Check if the widget is already mounted
      if (typeof window.FreshworksWidget !== 'undefined') {
        this.kill()
      }

      const script = document.createElement('script')
      script.src = `https://widget.freshworks.com/widgets/${widgetId}.js`
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    },
    kill() {
      try {
        window.FreshworksWidget('destroy')
      } catch (e) {
        // can throw serror when `destroy` is called before it was created
      }
    },
    revive() {
      window.FreshworksWidget('boot')
      window.FreshworksWidget('hide', 'launcher')
    },
  }

  const publicApi = {
    show() {
      window.FreshworksWidget('open')
    },
    hide() {
      window.FreshworksWidget('hide', 'launcher')
    },
  }

  window.FreshworksWidget('setLabels', {
    en: {
      contact_form: {
        title: gettext('Get in touch with us'),
        submit: gettext('Send'),
      },
    },
    nl: {
      contact_form: {
        title: gettext('Get in touch with us'),
        submit: gettext('Send'),
      },
    },
  })

  return { privateApi, publicApi }
}

export default function ({ store, $config }, inject) {
  const { privateApi, publicApi } = createFreshWorksWidgetController()

  store.watch(
    (state) => state.auth.languageCode,
    (locale) => {
      if (locale) {
        privateApi.init({ widgetId: $config.freshworksWidgetId, locale })
      }
    },
  )

  store.watch(
    (state) => state.auth.isLoggedIn,
    (isLoggedIn) => {
      if (!isLoggedIn) {
        privateApi.kill()
      }
    },
  )

  inject('freshworksWidget', publicApi)
}
