
import { MountingPortal } from 'portal-vue'
import preventBodyScroll from '~/utils/prevent-body-scroll'
import componentInstanceId from '~/mixins/component-instance-id'
import { dialogController } from '~/plugins/components/dialogs'
import { dialogContainer } from './dialog-container'

export default {
  components: {
    MountingPortal,
  },
  mixins: [componentInstanceId('dialog')],
  provide() {
    return {
      dialogId: this.computedId,
    }
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    canUserClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedId() {
      return this.id || this.$id
    },
    isOpen() {
      return dialogController.isOpen(this.computedId)
    },
    openedDialog() {
      return dialogController.opened
    },
  },
  watch: {
    open(newValue) {
      if (newValue) {
        dialogController.open(this.computedId)
      } else if (this.isOpen) {
        dialogController.close()
      }
    },
    openedDialog(newValue, oldValue) {
      if (newValue === this.computedId && oldValue !== this.computedId) {
        this.onOpen()
      }
    },
  },
  mounted() {
    if (this.open) {
      dialogController.open(this.computedId)
      this.onOpen()
    }
  },
  beforeCreate() {
    dialogContainer.create()
  },
  beforeDestroy() {
    if (this.isOpen) {
      dialogController.close()
      this.onClose()
    }
  },
  methods: {
    bindEventListeners() {
      document.body.addEventListener('mouseup', this.handleEvent)
    },
    unbindEventListeners() {
      document.body.removeEventListener('mouseup', this.handleEvent)
    },
    handleEvent(e) {
      if (dialogContainer.clickedOnBackdrop(e) && this.canUserClose) {
        dialogController.close()
      }
    },
    onOpen() {
      dialogContainer.showBackdrop()
      preventBodyScroll.enable()

      this.bindEventListeners()

      this.$emit('open')
    },
    onClose() {
      if (!this.openedDialog) {
        preventBodyScroll.disable()
        dialogContainer.hideBackdrop()
      }

      this.unbindEventListeners()

      this.$emit('close')
    },
  },
}
