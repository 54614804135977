
import ArrowDown from '~/assets/images/arrow-down.svg?inline'

export default {
  components: {
    ArrowDown,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    controlsId: {
      type: String,
      default: undefined,
    },
  },
}
