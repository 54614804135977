
import preventBodyScroll from '~/utils/prevent-body-scroll'
import ArrowBackIcon from '~/assets/images/arrow-back-icon.svg?inline'
import WOptionList from '../w-option-list/w-option-list'
import WSpinner from '../w-spinner/w-spinner'
import { logic, props } from './autosuggest-shared'

export default {
  components: {
    WOptionList,
    ArrowBackIcon,
    WSpinner,
  },
  mixins: [logic],
  inheritAttrs: false,
  props,
  data() {
    return {
      isFullScreen: false,
    }
  },
  computed: {
    shouldShowOptions() {
      return this.$canDoRequest && this.dataProvider.state === 'done'
    },
    $inputProps() {
      return {
        ...logic.computed.$inputProps.call(this),
        'aria-expanded': this.$canUseOptions,
      }
    },
  },
  created() {
    this.oldInputBounds = null
    this.scrollPositionBeforeFullScreen = 0
  },
  methods: {
    removeFocusFromInput() {
      document.activeElement.blur()
    },

    exitFullScreen() {
      if (this.isFullScreen) {
        this.isFullScreen = false
        window.scrollTo(0, this.scrollPositionBeforeFullScreen)
        this.$clear()
        this.removeFocusFromInput()
        this.animateToNewLocation()
        preventBodyScroll.disable()

        this.$emit('fullscreen-change', false)
      }
    },

    enterFullScreen() {
      if (!this.isFullScreen) {
        this.isFullScreen = true
        this.scrollPositionBeforeFullScreen = window.pageYOffset
        window.scrollTo(0, 0)
        setTimeout(() => {
          preventBodyScroll.enable()
        }, 200)

        this.$emit('fullscreen-change', true)
      }
    },

    animateToNewLocation() {
      const el = this.$refs.mobileInputWrapper

      if (!el) {
        return
      }

      this.oldInputBounds = el.getBoundingClientRect() // get old position

      this.$nextTick(() => {
        const newInputBounds = el.getBoundingClientRect() // get position after rendering fullscreen

        // offset to old position
        el.style.transition = 'none'
        el.style.transform = `translateY(${
          this.oldInputBounds.top - newInputBounds.top
        }px)`

        // trigger animation
        requestAnimationFrame(() => {
          el.style.transition = ''
          el.style.transform = ''
        })
      })
    },

    $onFocus() {
      this.enterFullScreen()
      logic.methods.$onFocus.call(this)
    },

    $onClickOption(text) {
      this.exitFullScreen()
      logic.methods.$onClickOption.call(this, text)
    },
  },
}
