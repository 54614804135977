// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { linkFields } from './elements/linkFragment.graphql'

export const termsRejectedNotice = gql`
  query TermsAndConditions($locale: SiteLocale) {
    termsAndConditionsModel(locale: $locale) {
      globalRejectedBannerCta
      globalRejectedBannerContent {
        value
        links {
          __typename
          id
          ...linkFields
        }
      }
    }
  }
  ${linkFields}
`
