export const state = () => {
  return {
    showAmounts: true,
  }
}

export const mutations = {
  toggleAmountVisibility(state) {
    state.showAmounts = !state.showAmounts
  },
}

export const actions = {
  toggleAmountVisibility({ commit }) {
    commit('toggleAmountVisibility')
  },
}

export const getters = {
  blurAmounts: (state) => !state.showAmounts,
}
