
import kebabCase from 'lodash.kebabcase'
import DropdownButton from '~/components/site-nav/header-navigation/dropdown-button.vue'
import { getIcon } from '~/utils/get-icon.js'

export default {
  name: 'NavigationItem',
  components: {
    DropdownButton,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    activeItem: {
      type: String,
      default: null,
    },
  },

  computed: {
    isActive() {
      return this.activeItem === this.item.label
    },

    dropdownId() {
      return kebabCase(this.item.label)
    },
  },

  methods: {
    buttonIcon(key) {
      return getIcon(key)
    },

    handleClickDropdown() {
      return !this.isActive
        ? this.$emit('set-active', this.item.label)
        : this.$emit('clear-active')
    },

    handleClickButton(item) {
      if (item.emit) {
        this.$emit(item.emit)
      }
    },
  },
}
