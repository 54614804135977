import { render, staticRenderFns } from "./site-header.vue?vue&type=template&id=7d9963ee&scoped=true"
import script from "./site-header.vue?vue&type=script&lang=js"
export * from "./site-header.vue?vue&type=script&lang=js"
import style0 from "./site-header.vue?vue&type=style&index=0&id=7d9963ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d9963ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WLogo: require('/app/components/global/w-logo/w-logo.vue').default,WIconButton: require('/app/components/global/w-icon-button/w-icon-button.vue').default,WPanelSeparator: require('/app/components/global/w-panel-separator/w-panel-separator.vue').default,WButton: require('/app/components/global/w-button/w-button.vue').default})
