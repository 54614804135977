
import WStack from '../w-stack/w-stack'

export default {
  components: {
    WStack,
  },
  methods: {
    login() {
      this.$store.dispatch('auth/idpSigninRedirect')
    },
  },
}
