// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { linkListFields } from './elements/linkListFragment.graphql'

export const footerRegisterQuery = gql`
  query FooterRegister($locale: SiteLocale) {
    footerRegister(locale: $locale) {
      menu {
        ...linkListFields
      }
    }
  }
  ${linkListFields}
`
