import Vue from 'vue'
import { hasValue } from '~/utils/optional-values'

if (!Vue.__mixins_optional_values__) {
  Vue.__mixins_optional_values__ = true

  Vue.mixin({
    methods: {
      $hasValue: hasValue,
    },
  })
}
