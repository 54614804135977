// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { linkFields } from './elements/linkFragment.graphql'
import { linkListFields } from './elements/linkListFragment.graphql'
import { sectionRichTextFields } from './sections/richtTextSection.graphql'

export const footerQuery = gql`
  query Footer($locale: SiteLocale) {
    footer(locale: $locale) {
      contactTitle
      contactLinks {
        ...linkFields
      }
      address {
        streetAndHouseNumber
        city
        postalCode
        country
      }
      socials {
        ...linkFields
      }
      menu {
        ...linkListFields
      }
      disclaimer {
        ...sectionRichTextFields
      }
    }
  }
  ${linkFields}
  ${linkListFields}
  ${sectionRichTextFields}
`
