
/* The unscaledStrokeWidth means that the stroke width is 14 at the default viewBox of 100x100.  */
const unscaledStrokeWidth = 18

export default {
  props: {
    appearDelay: {
      type: Number,
      default: 400,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    viewBox() {
      return [
        -this.unscaledStrokeWidth,
        -this.unscaledStrokeWidth,
        100 + this.unscaledStrokeWidth * 2,
        100 + this.unscaledStrokeWidth * 2,
      ].join(' ')
    },
  },
  created() {
    this.unscaledStrokeWidth = unscaledStrokeWidth
  },
}
