/* See event data for more information: https://dev.azure.com/SWI1/WWI/_wiki/wikis/WWI.wiki/111/Events-notifications-published-by-SignalR-for-WWI.FrontOffice */

export const values = {
  PspDepositCompleted: 'PspDepositCompleted',
  OrderPlaced: 'OrderPlaced',
  TradeOccurred: 'TradeOccurred',
  OrderCancelled: 'OrderCancelled',
  OrderExpired: 'OrderExpired',
  OrderFulfilled: 'OrderFulfilled',
  PortfolioIndexUpdated: 'PortfolioIndexUpdated',
  CategoryIndexUpdated: 'CategoryIndexUpdated',
}
