import { render, staticRenderFns } from "./site-footer.vue?vue&type=template&id=42b419a1&scoped=true"
import script from "./site-footer.vue?vue&type=script&lang=js"
export * from "./site-footer.vue?vue&type=script&lang=js"
import style0 from "./site-footer.vue?vue&type=style&index=0&id=42b419a1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b419a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WLogo: require('/app/components/global/w-logo/w-logo.vue').default})
