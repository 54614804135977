
export default {
  props: {
    wrap: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div',
    },
    orientation: {
      type: String,
      default: 'vertical',
    },
    gutter: {
      type: String,
      default: 'm',
    },
    justify: {
      type: String,
      default: 'start',
      validator: (val) =>
        ['start', 'end', 'space-between', 'space-around'].includes(val),
    },
  },
}
