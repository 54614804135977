let $body = null
let scrollPosition = 0
let enabled = false

export default {
  enable() {
    if (enabled || typeof document === 'undefined') {
      return
    }

    enabled = true
    $body = document.querySelector('body')
    scrollPosition = window.pageYOffset
    const canScroll =
      document.documentElement.scrollHeight >
      document.documentElement.clientHeight
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth
    $body.style.overflowY = scrollbarWidth && canScroll ? 'scroll' : 'hidden'
    $body.style.position = 'fixed'
    $body.style.top = `-${scrollPosition}px`
    $body.style.width = '100%'
  },
  disable() {
    if (!enabled) {
      return
    }
    enabled = false
    $body.style.removeProperty('overflow')
    $body.style.removeProperty('position')
    $body.style.removeProperty('top')
    $body.style.removeProperty('width')
    window.scrollTo(0, scrollPosition)
  },
}
