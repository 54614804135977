function isPojo(data) {
  return !!data && Object.prototype.toString.call(data) === '[object Object]'
}

function flattenObjectRecursive(obj, parentKey, depth, maxDepth) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const concatenatedKey = parentKey ? `${parentKey}.${key}` : key

    if (isPojo(value) && depth < maxDepth) {
      return {
        ...acc,
        ...flattenObjectRecursive(value, concatenatedKey, depth + 1, maxDepth),
      }
    } else if (typeof value !== 'undefined') {
      return { ...acc, [concatenatedKey]: value }
    }

    return acc
  }, Object.create(null))
}

export default function flattenObject(
  obj,
  maxDepth = Number.POSITIVE_INFINITY,
) {
  return flattenObjectRecursive(obj, undefined, 1, maxDepth)
}
