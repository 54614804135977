const ctx = require.context('./handlers', false, /\.js$/)

const handlers = ctx.keys().map((key) => ctx(key))

export function registerGlobalListeners(registerEvent, context) {
  handlers.forEach(({ eventName, handler }) => {
    registerEvent(eventName, (data) => {
      handler(data, context)
    })
  })
}
