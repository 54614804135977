export default ({ store, route, error }) => {
  const requiredPermissions = route.meta.reduce((acc, meta) => {
    return [...acc, ...(meta.requiredPermissions ?? [])]
  }, [])
  const permissions = store.getters['trader/permissions']
  if (!Array.isArray(requiredPermissions) || !requiredPermissions.length) {
    return true
  }
  const allPermissionsPassed = requiredPermissions.every(
    (key) => permissions[key],
  )
  if (!allPermissionsPassed) {
    return error({
      statusCode: 401,
    })
  }
  return allPermissionsPassed
}
