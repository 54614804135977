const clickOutside = Symbol('Click outside')

function unbind(el) {
  document.body.removeEventListener('mousedown', el[clickOutside])
  document.body.removeEventListener('focusout', el[clickOutside])
  delete el[clickOutside]
}

function bind(el, handler, { focus }) {
  el[clickOutside] = (e) => {
    const target = e.type === 'mousedown' ? e.target : e.relatedTarget
    if (!el.contains(target)) {
      handler(e)
    }
  }
  document.body.addEventListener('mousedown', el[clickOutside])
  focus && document.body.addEventListener('focusout', el[clickOutside])
}

/**
 * Detects whether the user clicks outside the target element or when focus moves outside the target element
 */
export default {
  bind(el, { value, modifiers }) {
    if (value) {
      bind(el, value, modifiers)
    }
  },
  update(el, { value, oldValue, modifiers }) {
    if (value !== oldValue) {
      unbind(el)
      if (value) {
        bind(el, value, modifiers)
      }
    }
  },
  unbind(el) {
    unbind(el)
  },
}
