export default function assert(value, error) {
  if (!value) {
    throw new Error(error)
  }
}

export const isBooleanOrStringBoolean = (value) =>
  typeof value === 'boolean' ||
  (typeof value === 'string' && /^(true|false)$/.test(value))

export const isMatchingBooleanOrStringBoolean = (value, match) =>
  typeof match === 'boolean' &&
  ((typeof value === 'boolean' && value === match) ||
    (typeof value === 'string' && value === String(match)))
