import assert from './assert'

export const EMPTY = null

/**
 * Returns true when the given value is not null or undefined
 * @param {*} data
 */
export function hasValue(data) {
  return typeof data !== 'undefined' && data !== EMPTY
}

/**
 * Executes a callback when data is not empty (null) otherwise returns null:
 * ifHasValue(null, (v) => v + 1) returns null
 * ifHasValue(1, (v) => v + 1) returns 2
 *
 * When only given a callback the function which will return a partially applied closure:
 * const closure = ifHasValue((v) => do something with v)
 * closure(someValue)
 *
 * @param {*} value
 * @param {function} cb
 * @returns {*}
 */
export function ifHasValue(value, cb) {
  if (typeof value === 'function') {
    cb = value
    return (value, ...otherArgs) =>
      hasValue(value) ? cb(value, ...otherArgs) : EMPTY
  } else {
    assert(cb, 'Please provide a callback to ifHasValue')
    return hasValue(value) ? cb(value) : EMPTY
  }
}
