import Vue from 'vue'

Vue.use({
  install() {
    Vue.mixin({
      created() {
        this.blockedToasts = new Set()
      },
      destroyed() {
        this.blockedToasts.forEach((event) => {
          this.$store.toasts.unblockToast(event)
        })
      },
      methods: {
        $triggerToast(toast) {
          this.$store.dispatch('toasts/triggerToast', toast)
        },
        $blockToast(event) {
          this.blockedToasts.add(event)
          this.$store.toasts.dispatch('toasts/blockToast', event)
          return () => this.$unblockToast(event)
        },
        $unblockToast(event) {
          this.$store.toasts.dispatch('toasts/unblockToast', event)
          this.blockedToasts.delete(event)
        },
      },
    })
  },
})
