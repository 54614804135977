import { render, staticRenderFns } from "./error.vue?vue&type=template&id=3a083d9b"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=3a083d9b&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WButton: require('/app/components/global/w-button/w-button.vue').default,WStack: require('/app/components/global/w-stack/w-stack.vue').default})
