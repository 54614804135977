// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { linkFields } from '../elements/linkFragment.graphql'
import { imageFields } from '../elements/imageFragment.graphql'

export const sectionRichTextFields = gql`
  fragment sectionRichTextFields on SectionRichTextRecord {
    modelApiKey: _modelApiKey
    richText {
      value
      links {
        __typename
        id
        ...linkFields
      }
      blocks {
        ... on VideoRecord {
          __typename
          id
          video {
            providerUid
          }
        }
        ... on ImageRecord {
          __typename
          id
          image {
            ...imageFields
          }
        }
      }
    }
  }
  ${linkFields}
  ${imageFields}
`
