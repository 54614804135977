import { render, staticRenderFns } from "./w-search-form.vue?vue&type=template&id=5aa92a36"
import script from "./w-search-form.vue?vue&type=script&lang=js"
export * from "./w-search-form.vue?vue&type=script&lang=js"
import style0 from "./w-search-form.vue?vue&type=style&index=0&id=5aa92a36&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WButton: require('/app/components/global/w-button/w-button.vue').default,WSimpleOption: require('/app/components/global/w-option-list/option-types/w-simple-option.vue').default,WAutosuggest: require('/app/components/global/w-autosuggest/w-autosuggest.vue').default})
