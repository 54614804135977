import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

export default ({ $config, i18n }, inject) => {
  const language = i18n.localeProperties.iso

  // https://authts.github.io/oidc-client-ts/classes/WebStorageStateStore.html
  const idpUserStorage = new WebStorageStateStore({
    store: window.localStorage,
  })

  // https://authts.github.io/oidc-client-ts/classes/UserManager.html
  const idpUserManager = new UserManager({
    authority: $config.identityServerUrl,
    scope: 'openid offline_access swi_platform_api',
    client_id: 'spa',
    redirect_uri: $config.identityServerCallback,
    response_type: 'code',
    userStore: idpUserStorage,
    extraHeaders: {
      'Accept-language': language,
    },
  })

  inject('idpUserStorage', idpUserStorage)
  inject('idpUserManager', idpUserManager)
}
