
import DropdownButton from '~/components/site-nav/header-navigation/dropdown-button.vue'

export default {
  components: {
    DropdownButton,
  },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    isLanguageSelectorOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
}
