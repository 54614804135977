
import componentInstanceId from '~/mixins/component-instance-id'
import WStack from '../w-stack/w-stack'
import WSimpleOption from './option-types/w-simple-option.vue'
import actionPresets from './action-presets'

const { orientation, wrap, gutter } = WStack.props

const keysByOrientation = {
  vertical: {
    decr: 'ArrowUp',
    incr: 'ArrowDown',
  },
  horizontal: {
    decr: 'ArrowLeft',
    incr: 'ArrowRight',
  },
}

export default {
  components: {
    WStack,
    WSimpleOption,
  },
  mixins: [componentInstanceId('option-list')],
  props: {
    orientation,
    wrap,
    gutter,
    scrollable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
      validator(value) {
        return value.every((item) => 'id' in item)
      },
    },
    value: {
      type: [Array, String, Number, Object],
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    action: {
      type: [Function, String],
      default: 'select',
    },
  },
  data() {
    return {
      activeIndex: -1,
    }
  },
  computed: {
    activeOptionId() {
      return this.activeId !== undefined ? this.getOptionId(this.activeId) : ''
    },

    activeId() {
      if (!this.options[this.activeIndex]) {
        return undefined
      }
      return this.options[this.activeIndex].id
    },

    firstSelectedIndex() {
      const firstSelectedId = this.extractId(
        Array.isArray(this.normalizedValue)
          ? this.normalizedValue[0]
          : this.normalizedValue,
      )
      return firstSelectedId
        ? this.options.findIndex(({ id }) => id === firstSelectedId)
        : -1
    },

    valuesGroupedById() {
      let grouped
      const toKeyPair = (value) => [this.extractId(value), value]
      if (Array.isArray(this.normalizedValue)) {
        grouped = new Map(this.normalizedValue.map(toKeyPair))
      } else {
        grouped = new Map()
        if (this.normalizedValue !== undefined) {
          const [id, value] = toKeyPair(this.normalizedValue)
          grouped.set(id, value)
        }
      }
      return grouped
    },

    /**
     * Create a Set object from the normalizedValues.
     * The lookup speed of a Set is a lot faster then that of an array.
     * We also extract the ids for complex values. E.g. if the value is an object then we expect the object to have an id.
     */
    selectedIds() {
      let ids
      if (Array.isArray(this.normalizedValue)) {
        ids = new Set(this.normalizedValue.map(this.extractId))
      } else {
        ids = new Set()
        if (this.normalizedValue !== undefined) {
          ids.add(this.extractId(this.normalizedValue))
        }
      }
      return ids
    },

    /**
     * Fixes value to be what we expect:
     * - If it's possible to select multiple values then we expect value to be an array
     * - If not then the value should not be an array (if it is then let's just pick the first item)
     */
    normalizedValue() {
      if (this.value !== undefined) {
        if (this.multiple && !Array.isArray(this.value)) {
          return [this.value]
        } else if (!this.multiple && Array.isArray(this.value)) {
          return this.value[0]
        }
      }
      return this.value
    },
  },
  watch: {
    options() {
      this.updateActiveIndex(
        Math.min(this.activeIndex, this.options.length - 1),
      )
    },
  },
  methods: {
    getOptionId(id) {
      return `${this.$id}-option-${id}`
    },

    extractId(value) {
      return value?.id !== undefined ? value.id : value
    },

    getSelectedValue(id) {
      return this.valuesGroupedById.get(id)
    },

    isSelected(id) {
      return this.valuesGroupedById.has(id)
    },

    isActive(option) {
      return !option.disabled && this.activeId === option.id
    },

    clickOption(id) {
      const newIndex = this.options.findIndex((option) => option.id === id)
      this.updateActiveIndex(newIndex, newIndex >= this.activeIndex ? 1 : -1)

      const newId = this.executeAction(id)

      let newValue
      if (this.multiple) {
        if (newId) {
          newValue = [...this.normalizedValue, newId]
        } else {
          newValue = this.normalizedValue.filter(
            (value) => this.extractId(value) !== id,
          )
        }
      } else {
        newValue = newId
      }

      if (JSON.stringify(this.normalizedValue) !== JSON.stringify(newValue)) {
        this.$emit('input', newValue)
      }

      this.$emit('click-option', id)
    },

    executeAction(id) {
      const computedAction =
        typeof this.action === 'function'
          ? this.action
          : actionPresets[this.action]

      if (!computedAction) {
        throw new Error(
          'No click action defined for option. Either specify one of the action-presets or pass a custom action function',
        )
      }

      return computedAction(id, {
        isSelected: this.isSelected(id),
        selectedValue: this.getSelectedValue(id),
        optionList: this,
      })
    },

    increaseActiveIndex() {
      let newIndex = this.activeIndex + 1
      if (newIndex >= this.options.length) {
        newIndex = 0
      }
      this.updateActiveIndex(newIndex, 1)
    },

    decreaseActiveIndex() {
      let newIndex = this.activeIndex - 1
      if (newIndex < 0) {
        newIndex = this.options.length - 1
      }
      this.updateActiveIndex(newIndex, -1)
    },

    /**
     * Move to the nearest enabled index while skipping disabled options.
     * Direction determines if the cursor moves forward or backwards in the options list
     * @param {Number} index
     * @param {Number} direction
     */
    moveToNearestEnabledIndex(index, direction = 1) {
      for (
        ;
        this.options[index] && this.options[index].disabled;
        index += direction
      );
      return index >= 0 && index < this.options.length
        ? index
        : this.activeIndex
    },

    scrollOptionIntoView(index) {
      if (this.orientation === 'vertical') {
        this.$refs.option[index]?.scrollIntoView({
          [this.orientation === 'vertical' ? 'block' : 'inline']: 'nearest',
        })
      }
    },

    updateActiveIndex(newIndex, direction = 1) {
      if (newIndex !== -1) {
        newIndex = this.moveToNearestEnabledIndex(newIndex, direction)

        this.scrollOptionIntoView(newIndex)
      }

      if (newIndex !== this.activeIndex) {
        this.activeIndex = newIndex
        this.$emit('change-active', {
          index: this.activeIndex,
          id: this.activeId,
          optionId: this.activeOptionId,
        })
      }
    },

    /**
     * Reset active index if user tabs out of list
     */
    onFocusOut(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.updateActiveIndex(-1)
      }
    },

    /**
     * When focusing in the option-list we immediately move the focus to the first (selected) option
     */
    onFocus() {
      this.updateActiveIndex(
        this.firstSelectedIndex > -1 ? this.firstSelectedIndex : 0,
      )
    },

    moveToIndexById(id) {
      const index = this.options.findIndex((option) => option.id === id)
      if (index > -1) {
        this.updateActiveIndex(index)
      }
    },

    onKeyDown(e) {
      const keys = keysByOrientation[this.orientation]
      switch (e.key) {
        case keys.incr:
          e.preventDefault()
          this.increaseActiveIndex()
          break
        case keys.decr:
          e.preventDefault()
          this.decreaseActiveIndex()
          break
        case 'Home':
          e.preventDefault()
          this.updateActiveIndex(0, -1)
          break
        case 'End':
          e.preventDefault()
          this.updateActiveIndex(this.options.length - 1)
          break
        case 'Backspace':
        case 'Delete':
          e.preventDefault()
          this.$emit('delete', this.activeId)
          break
        case 'Enter':
        case ' ':
          e.preventDefault()
          if (this.activeId) {
            this.clickOption(this.activeId)
          }
          break
      }
    },
  },
}
