
import componentInstanceId from '~/mixins/component-instance-id'
import SearchIcon from '~/assets/images/search-icon.svg?inline'

export default {
  components: { SearchIcon },
  mixins: [componentInstanceId('search-form')],
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autosuggestDataProvider: {
      type: Object,
      required: true,
    },
    showMobileFullScreen: {
      type: Boolean,
      default: true,
    },
    inHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.text = newValue
    },
  },
  methods: {
    onSubmit() {
      document.activeElement.blur()
      this.$emit('submit', this.text)
    },
    focus() {
      this.$refs.searchInput.focus()
    },
    onChange(...args) {
      this.$emit('change', ...args)
    },
    onFullScreenChange(...args) {
      this.$emit('fullscreen-change', ...args)
    },
  },
}
