
import { createPopperLite as createPopper, offset, flip } from '@popperjs/core'
import componentInstanceId from '~/mixins/component-instance-id'
import clickOutside from '~/directives/click-outside'

export default {
  directives: {
    clickOutside,
  },

  mixins: [componentInstanceId('popover')],

  props: {
    positioningStrategy: {
      type: String,
      default: 'absolute',
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    flipFallbackPlacements: {
      type: Array,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: [Array, Function],
      default: () => [0, 0],
    },
    transitionName: {
      type: String,
      default: 'popover-default-transition',
    },
  },

  data() {
    return {
      popupVisible: false,
    }
  },

  watch: {
    show(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createPopper()
          this.$emit('popup-shown')
        })
        this.popupVisible = true
      }
    },
    positioningStrategy() {
      this.popper?.setOptions(this.getPopperOptions())
    },
    placement() {
      this.popper?.setOptions(this.getPopperOptions())
    },
    preventFlip() {
      this.popper?.setOptions(this.getPopperOptions())
    },
  },

  destroyed() {
    this.destroyPopper()
  },

  methods: {
    onAfterTransitionLeave() {
      this.destroyPopper()
      this.popupVisible = false
      this.$emit('after-hide')
    },

    getPopperOptions() {
      const modifiers = [
        {
          ...offset,
          options: {
            offset: this.offset,
          },
        },
      ]

      if (
        !this.flipFallbackPlacements ||
        this.flipFallbackPlacements.length > 0
      ) {
        modifiers.push({
          ...flip,
          options: {
            fallbackPlacements: this.flipFallbackPlacements ?? undefined,
          },
        })
      }

      return {
        placement: this.placement,
        strategy: this.positioningStrategy,
        modifiers,
      }
    },

    getAnchorElement() {
      let anchor = this.$el.querySelector(':scope > [data-anchor]')
      if (!anchor) {
        anchor = this.$el.querySelector(':scope > *:first-child')
      }
      if (!anchor) {
        throw new Error(
          'No anchor found for popover! The anchor should be either an element with a data-anchor attribute or the first child of the popover container.',
        )
      }
      return anchor
    },

    getPopupElement() {
      return this.$refs.popup
    },

    createPopper() {
      if (this.popper || !this.show) {
        return
      }

      this.popper = createPopper(
        this.getAnchorElement(),
        this.getPopupElement(),
        this.getPopperOptions(),
      )
    },

    destroyPopper() {
      if (!this.popper || this.show) {
        return
      }

      this.popper.destroy()
      this.popper = null
    },

    hide(e) {
      this.$emit('hide', e)
    },
  },
}
