
import { footerRegisterQuery } from '~/gql/footerRegister.graphql'

export default {
  data() {
    return {
      menu: null,
    }
  },

  async fetch() {
    await this.getFooterContent()
  },

  computed: {
    locale() {
      return this.$i18n.locale
    },

    availableLocales() {
      return this.$i18n.locales
    },

    isLoadingLanguage() {
      return this.$i18nTranslations.isLoading
    },
  },

  methods: {
    onChangeLanguage(locale) {
      const url = this.$nuxt.context.app.switchLocalePath(locale)

      this.$i18n.setLocale(locale)

      this.$router.push(url)

      this.getFooterContent()
    },

    async getFooterContent() {
      const variables = {
        locale: this.$i18n.locale,
      }

      const { footerRegister } = await this.$graphql.default.request(
        footerRegisterQuery,
        variables,
      )

      this.menu = footerRegister.menu
    },
  },
}
