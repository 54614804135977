import Vue from 'vue'

export const dialogController = (function () {
  const state = Vue.observable({ opened: null })

  return {
    get opened() {
      return state.opened
    },

    isOpen(id) {
      return state.opened === id
    },

    open(id) {
      const elem = document.getElementById('w-dialogs-container')
      elem.opened = true

      state.opened = id
    },

    close() {
      state.opened = null

      // MWA: ugly hack to close window by clicking on backdrop, unfortunately
      // seems to be the only way to make the cancel button work from all
      // variations I tried.
      // Rationale as follows:
      // Main problem is that in this method when called from dialog tge `this` reference
      // is null, therefore it does not have the right reference context. My guess is,
      // that this has something to do with nuxt hydrating and dehydrating components.
      // I have tried making this component client-only, but to no avail.
      const elem = document.getElementById('w-dialogs-container')

      if (!elem.opened) {
        return
      }

      elem.opened = false

      if (elem) {
        const event = new MouseEvent('mouseup', {
          view: window,
          cancelable: true,
          bubbles: true,
        })
        elem.dispatchEvent(event)
      }
    },
  }
})()

/**
 * This plugin will make the dialogController available to each component through this.$dialogs
 */

if (!Vue.__mixins__dialogs) {
  Vue.__mixins__dialogs = true

  Vue.mixin({
    computed: {
      $dialogs: {
        get() {
          return dialogController
        },
      },
    },
  })
}
