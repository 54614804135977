
import WToastItem from './w-toast-item.vue'
export default {
  components: { WToastItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    verticalAlignment: {
      type: String,
      default: 'bottom',
    },
    horizontalAlignment: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    sortedByDate() {
      return [...this.items].sort((a, b) => {
        return Date.parse(b.date) - Date.parse(a.date)
      })
    },
  },
  methods: {
    onClickDismiss(id) {
      this.$emit('dismiss', id)
    },
    onBeforeLeave(el) {
      const oldTop = el.getBoundingClientRect().top
      el.style.position = 'absolute'
      el.style.marginTop = '0px'
      el.style.top = '0px'
      const listTop = this.$refs.list.$el.getBoundingClientRect().top
      const offset = Math.round(oldTop - listTop)
      el.style.top = `${offset}px`
    },
  },
}
