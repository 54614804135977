// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { linkFields } from './linkFragment.graphql'

export const linkListFields = gql`
  fragment linkListFields on LinkListRecord {
    title
    links {
      ...linkFields
    }
  }
  ${linkFields}
`
