
import { mapGetters, mapActions, mapState } from 'vuex'

import SiteFooter from '~/components/site-nav/site-footer'
import SiteHeader from '~/components/site-nav/header-navigation/site-header.vue'
import RegistrationMessage from '~/components/registration-message/registration-message.vue'
import TermsAndConditionsDialog from '~/components/terms-and-conditions/terms-and-conditions-dialog'
import OfferDialog from '~/components/page-explore/offer-dialog'
import RichTextSection from '~/components/public/rich-text-section.vue'

import { termsRejectedNotice } from '~/gql/termsRejectedNotice.graphql'

import { removeRouteNameLanguageSuffix } from '~/utils/remove-route-name-language-suffix'
import { getMetaTags } from '~/utils/get-meta-tags'

export default {
  components: {
    SiteHeader,
    SiteFooter,
    RegistrationMessage,
    TermsAndConditionsDialog,
    OfferDialog,
    RichTextSection,
  },

  data() {
    return {
      dialogType: null,
      globalTermsRejectedBannerContent: null,
      globalTermsRejectedBannerCta: null,
    }
  },

  fetch() {
    return this.$i18nTranslations.loadTranslations()
  },

  head() {
    let addSeoAttributes = true

    if (this.isSlugPage) {
      // To prevent adding incorrect alternates
      addSeoAttributes = false
    }

    return {
      ...this.$nuxtI18nHead({ addSeoAttributes }),
      meta: getMetaTags(this.$config),
    }
  },

  computed: {
    ...mapGetters('toasts', ['items']),
    ...mapState('trader', [
      'hasConsented',
      'shouldPresentTermsAndConditionsModal',
      'hasOffering',
    ]),
    ...mapState('infoDialog', [
      'isOpen',
      'title',
      'componentName',
      'componentProps',
    ]),
    displayTermsRejectedNotice() {
      return (
        this.hasConsented === false &&
        !this.shouldPresentTermsAndConditionsModal
      )
    },
    isSlugPage() {
      const slugPages = [
        'slug',
        'articles-slug',
        'lessons-slug',
        'workshops-and-webinars-slug',
      ]

      const routeName = removeRouteNameLanguageSuffix(this.$route.name)

      return slugPages.includes(routeName)
    },
  },

  watch: {
    shouldPresentTermsAndConditionsModal() {
      this.attemptSetTermsDialog()

      if (this.displayTermsRejectedNotice) {
        this.fetchTermsRejectedContent()
      }
    },

    hasOffering() {
      this.attemptSetOfferDialog()
    },

    '$i18n.locale'() {
      if (this.displayTermsRejectedNotice) {
        this.fetchTermsRejectedContent()
      }
    },
  },

  mounted() {
    this.attemptSetTermsDialog()
    this.attemptSetOfferDialog()
  },

  methods: {
    ...mapActions('toasts', ['dismissToast']),
    ...mapActions('trader', ['loadKeyFigures']),

    handleChangeConsent() {
      this.dialogType = 'terms'
    },

    async fetchTermsRejectedContent() {
      const variables = {
        locale: this.$i18n.locale,
      }

      const { termsAndConditionsModel } = await this.$graphql.default.request(
        termsRejectedNotice,
        variables,
      )

      this.globalTermsRejectedBannerContent =
        termsAndConditionsModel.globalRejectedBannerContent
      this.globalTermsRejectedBannerCta =
        termsAndConditionsModel.globalRejectedBannerCta
    },

    attemptSetTermsDialog() {
      /**
       * As we're linking to the rates page for extra information,
       * do not try to trigger the popup here
       */
      const ratesPages = ['rates', 'tarieven']

      if (
        this.shouldPresentTermsAndConditionsModal &&
        !ratesPages.includes(this.$route.params?.slug)
      ) {
        this.dialogType = 'terms'
      }
    },

    attemptSetOfferDialog() {
      if (this.hasOffering) {
        this.dialogType = 'offer'
      }
    },
  },
}
