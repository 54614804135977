/**
 * Add your custom breakpoints here
 *
 * These breakpoints can be referenced in your css like this:
 *
 * @media (--m-sm) {
 *    // custom styles
 * }
 *
 * or can also be used in your js (see matches-breakpoint.js as an example)
 *
 * Reference: https://github.com/postcss/postcss-custom-media
 */
module.exports = {
  customMedia: {
    // todo: depracate this one
    '--m-sm': '(max-width: 599px)',

    '--m-s': '(min-width: 40rem)',
    '--m-m': '(min-width: 60rem)',
    '--m-l': '(min-width: 75rem)',
  },

  keys() {
    return Object.keys(module.exports.customMedia).map((key) =>
      key.replace(/^--m-/, ''),
    )
  },
}
