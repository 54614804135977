
// TODO: refactor api error message to implement displaying logic on page or template level
import { AsyncError } from '~/utils/async-action'

export default {
  props: {
    error: {
      type: [Object, Error],
      default: null,
    },
  },

  computed: {
    validationErrors() {
      if (
        this.error instanceof AsyncError &&
        this.error.validationErrors?.toString() === '[object Object]'
      ) {
        return this.getValidationErrors(this.error.validationErrors)
      } else if (this.error.isAxiosError && this.error.response?.data?.errors) {
        return this.getValidationErrors(this.error.response?.data?.errors)
      }
      return []
    },

    errorMessage() {
      if (this.error.isAxiosError) {
        return this.error.response?.data?.detail ?? ''
      } else if (this.error instanceof AsyncError) {
        return this.error.message
      }
      return ''
    },
  },

  methods: {
    getValidationErrors(validationErrors) {
      return Object.values(validationErrors).reduce(
        (acc, errorMessages) => [...acc, ...errorMessages],
        [],
      )
    },
  },
}
