
import { getMetaTags } from '~/utils/get-meta-tags'
export default {
  fetch() {
    return this.$i18nTranslations.loadTranslations()
  },

  head() {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      meta: getMetaTags(this.$config),
    }
  },
}
