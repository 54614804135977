
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    getSelectedValue: {
      type: Function,
      default: null,
    },
  },
}
