
import NavigationItem from '~/components/site-nav/header-navigation/navigation-item.vue'

export default {
  components: {
    NavigationItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    activeItem: {
      type: String,
      default: null,
    },
  },
}
