import { render, staticRenderFns } from "./w-message.vue?vue&type=template&id=3a96eafc"
import script from "./w-message.vue?vue&type=script&lang=js"
export * from "./w-message.vue?vue&type=script&lang=js"
import style0 from "./w-message.vue?vue&type=style&index=0&id=3a96eafc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WButton: require('/app/components/global/w-button/w-button.vue').default})
