import Vue from 'vue'
import { gettext } from '~/i18n/catalog'
import { ifHasValue } from '../utils/optional-values'

const state = Vue.observable({
  percentFormatter: null,
  numberFormatter: null,
  currencyFormatters: null,
  dateFormatters: null,
  currencySymbol: null,
})

updateFormatters('en', 'EUR')

export function updateFormatters(
  locale,
  currencySymbol = state.currencySymbol,
) {
  if (locale === 'en') {
    // Force en-GB locale when english is selected
    locale = 'en-GB'
  }

  const formatters = Object.create(null)

  formatters.percentFormatter = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })

  formatters.numberFormatter = new Intl.NumberFormat(locale, {})

  formatters.currencyFormatters = {
    default: new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencySymbol,
    }),

    rounded: new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencySymbol,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  }

  formatters.dateFormatters = {
    default: new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),

    dateTime: new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }),

    month: new Intl.DateTimeFormat(locale, {
      month: 'short',
    }),
  }

  state.currencySymbol = currencySymbol
  Object.assign(state, Object.freeze(formatters))
}

const toNumber = (value, defaultValue = 0) => {
  const maybeNumber = parseFloat(value)
  return Number.isNaN(maybeNumber) ? defaultValue : maybeNumber
}

export const formatAsPercentage = ifHasValue((value) =>
  state.percentFormatter.format(toNumber(value) / 100),
)

export const formatAsCurrency = ifHasValue((value, variant = 'default') =>
  state.currencyFormatters[variant].format(toNumber(value)),
)

export const formatAsCurrencyParts = ifHasValue((value, variant = 'default') =>
  state.currencyFormatters[variant].formatToParts(toNumber(value)),
)

export const formatAsNumber = ifHasValue((value) => {
  return state.numberFormatter.format(toNumber(value))
})

export const formatAsAmount = ifHasValue((value) => {
  // There is number formatting for units like 'milliliter' but it is not well supported.
  return toNumber(value) + ' ml'
})

export const formatAsDate = ifHasValue((value, variant = 'default') => {
  let valueAsDate = value
  if (typeof value === 'string') {
    valueAsDate = new Date(value)
  }
  return state.dateFormatters[variant].format(valueAsDate)
})

export const formatAsMonth = ifHasValue((value, variant = 'month') => {
  let valueAsMonth = value
  if (typeof value === 'string') {
    valueAsMonth = new Date(value)
  }
  return state.dateFormatters[variant].format(valueAsMonth)
})

export const formatAsDateParts = ifHasValue((value, variant = 'default') => {
  let valueAsDate = value
  if (typeof value === 'string') {
    valueAsDate = new Date(value)
  }
  return state.dateFormatters[variant].formatToParts(valueAsDate)
})

export const formatAsString = ifHasValue((value) => {
  return gettext(value)
})

const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const formatAsDecimalNumber = ifHasValue((value) => {
  const roundedNumber = round(toNumber(value), 1)
  return state.numberFormatter.format(roundedNumber)
})

export const getFormatterByType = (type) => {
  switch (type) {
    case 'currency':
      return formatAsCurrency
    case 'date':
      return formatAsDate
    case 'dateTime':
      return (v) => formatAsDate(v, 'dateTime')
    case 'number':
      return formatAsNumber
    case 'percentage':
      return formatAsPercentage
    case 'decimal':
      return formatAsDecimalNumber
    case 'month':
      return formatAsMonth
    case 'string':
      return formatAsString
    default: {
      return null
    }
  }
}
