export const types = {
  Basket: 'Basket',
  Bottle: 'Bottle',
  Cask: 'Cask',
  SearchResult: 'SearchResult',
}

export const isBasket = ({ itemType }) => itemType === types.Basket

export const isBottle = ({ itemType }) => itemType === types.Bottle

export const isCask = ({ itemType }) => itemType === types.Cask
