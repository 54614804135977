/*
    This file contains the actions which can occur when clicking / selecting one of the items in the option-list.

    Returning a value from the action will: set the value as the selection or append that value to the list of selections (when multiple can be selected)
    Returning a undefined will: set undefined as the selection (nothing is selected) or remove the value from the selection
*/

export default {
  select: (id) => id,
  toggle: (id, { isSelected }) => (isSelected ? undefined : id),
  toggleSort: (id, { selectedValue, optionList }) => {
    // The next code will unselect a sort option from a multi select list
    if (
      optionList.multiple &&
      optionList.selectedIds.size > 1 &&
      selectedValue?.sortOrder === 'desc'
    ) {
      return undefined
    }

    // empty id will not trigger any change in sort order (this is the default empty sort option and changing the sort order is not supported)
    if (id === '') {
      return { id }
    }

    return {
      id,
      sortOrder: selectedValue?.sortOrder === 'asc' ? 'desc' : 'asc',
    }
  },
}
