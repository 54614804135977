
import { StructuredText } from 'vue-datocms'
import { h } from 'vue'

export default {
  components: {
    StructuredText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLink(record) {
      if (!record) {
        return null
      }

      const href = this.$getLinkFromDato(record)
      const attributes = {
        href,
        ...(record.targetBlank ? { target: '_blank' } : {}),
      }

      return h(
        'a',
        {
          attrs: attributes,
        },
        record.label,
      )
    },
    renderInlineRecord({ record }) {
      switch (record.__typename) {
        case 'LinkRecord':
          return this.getLink(record)
        default:
          return null
      }
    },
    renderLinkToRecord({ record }) {
      switch (record.__typename) {
        case 'LinkRecord':
          return this.getLink(record)
        default:
          return null
      }
    },
    renderBlock({ record }) {
      switch (record.__typename) {
        case 'ImageRecord':
          return h('img', {
            attrs: {
              src: record.image.responsiveImage.src,
              srcSet: record.image.responsiveImage.srcSet,
              sizes: record.image.responsiveImage.sizes,
              width: record.image.responsiveImage.width,
              height: record.image.responsiveImage.height,
              alt: record.image.responsiveImage.alt,
            },
          })
        case 'VideoRecord':
          return h('iframe', {
            attrs: {
              src: 'https://player.vimeo.com/video/' + record.video.providerUid,
              frameBorder: '0',
              allow: 'autoplay; fullscreen; picture-in-picture',
              allowFullScreen: true,
            },
          })
        default:
          return null
      }
    },
  },
}
