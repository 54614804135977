
import * as endpoints from '~/api/endpoints'
import RichTextSection from '~/components/public/rich-text-section.vue'
import { termsAndConditionsQuery } from '~/gql/termsConditions.graphql'
import { getIcon } from '~/utils/get-icon'

export default {
  components: {
    RichTextSection,
  },

  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      confirmingRejection: false,
      confirmingRejectionText: null,
      confirmRejectionTitle: null,
      feeNoticeText: null,
      hasScrolledToBottom: false,
      loading: false,
      terms: null,
    }
  },

  async fetch() {
    const variables = {
      locale: this.$i18n.locale,
    }

    const { termsAndConditionsModel } = await this.$graphql.default.request(
      termsAndConditionsQuery,
      variables,
    )

    this.terms = termsAndConditionsModel.text
    this.confirmingRejectionText =
      termsAndConditionsModel.confirmRejectionContent
    this.confirmRejectionTitle = termsAndConditionsModel.confirmRejectionTitle
    this.feeNoticeText = termsAndConditionsModel.feeNoticeText
  },

  computed: {
    pageTitle() {
      return this.confirmingRejection
        ? this.confirmRejectionTitle
        : this.$gettext('Terms and conditions')
    },
  },

  methods: {
    getButtonIcon(key) {
      return getIcon(key)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.hasScrolledToBottom = true
      }
    },
    handleConsent(hasConsented) {
      this.loading = true

      this.$axios
        .post(endpoints.getTraderConsentStatus, {
          hasConsented,
        })
        .finally(() => {
          this.loading = false
          this.$dialogs.close()

          location.reload()
        })
    },
    onDecline() {
      if (this.confirmingRejection) {
        return this.handleConsent(false)
      }

      this.confirmingRejection = true
      document.querySelector('.w-dialog-layout__content').scrollTo(0, 0)
    },
  },
}
