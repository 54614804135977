
import WButtonLook, { themeableProps } from '../w-button-look/w-button-look'

const { disabled, variant, size, color } = themeableProps

export default {
  components: {
    WButtonLook,
  },
  props: {
    disabled,
    variant,
    size,
    color,
    href: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      required: true,
    },
  },
  computed: {
    themeablePropValues() {
      return {
        disabled: this.disabled,
        variant: this.variant,
        size: this.size,
        color: this.color,
      }
    },
    conditionalProps() {
      if (this.component === 'nuxt-link') {
        return { to: this.href }
      }

      if (this.component === 'a') {
        return {
          href: this.href,
          target: '_blank',
          rel: 'noreferer noopener',
        }
      }

      return {}
    },

    component() {
      if (this.href) {
        return this.href.match(/^(https?)?:\/\//) ? 'a' : 'nuxt-link'
      }

      return 'button'
    },
  },
}
