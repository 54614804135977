
import WPopover from '../w-popover/w-popover.vue'
import WOptionList from '../w-option-list/w-option-list'
import WSpinner from '../w-spinner/w-spinner'
import { logic, props } from './autosuggest-shared'

export default {
  components: { WPopover, WOptionList, WSpinner },
  mixins: [logic],
  inheritAttrs: false,
  props,

  data() {
    return {
      popupOpen: false,
    }
  },

  computed: {
    shouldShowPopup() {
      return (
        // you can walk through the options
        this.$canUseOptions ||
        // request is done, but no results where found (we show the no results text)
        (this.$canDoRequest &&
          this.focused &&
          this.dataProvider.state === 'done')
      )
    },

    $inputEvents() {
      return {
        ...logic.computed.$inputEvents.call(this),
        click: this.onClickInput,
      }
    },
  },

  watch: {
    shouldShowPopup(newValue) {
      newValue ? this.showPopup() : this.hidePopup()
    },
  },

  created() {
    this.onAfterHideCallback = undefined
  },

  methods: {
    showPopup() {
      if (!this.popupOpen) {
        this.onAfterHideCallback = undefined
        this.popupOpen = true
      }
    },

    hidePopup(callback) {
      if (this.popupOpen) {
        this.popupOpen = false
        this.onAfterHideCallback = callback
        this.$moveVisualFocusToInput()
      }
    },

    onAfterHide() {
      this.onAfterHideCallback?.()
      this.onAfterHideCallback = undefined
    },

    onPopOverHide() {
      this.hidePopup()
    },

    onClickInput() {
      if (this.shouldShowPopup) {
        this.showPopup()
      } else if (this.$canDoRequest && this.dataProvider.state !== 'pending') {
        /* added pending check to prevent double request */
        this.$triggerRequest()
      }
    },

    $clear() {
      if (this.popupOpen) {
        this.hidePopup(() => {
          logic.methods.$clear.call(this)
        })
      } else {
        logic.methods.$clear.call(this)
      }
    },

    $onClickOption(id) {
      this.$clear()
      logic.methods.$onClickOption.call(this, id)
    },

    $onPressKey(e) {
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowUp': {
          if (!this.$canDoRequest) {
            break
          }

          if (!this.popupOpen) {
            if (this.dataProvider.state === 'done') {
              /* we still have options to display. Just show them */
              this.$moveVisualFocusToOptions()
              this.showPopup()

              if (e.key === 'ArrowDown') {
                /* showPopup already sets focus to option-list. No need to retrigger a key event */
                return
              }
            } else {
              /* we can't show the options but we can do a request. Let's do it and also redirect  */
              const eventClone = new KeyboardEvent(e.type, e)
              this.dataProvider.request(this.text).then(() => {
                this.$moveVisualFocusToOptions()
                this.$redirectKeyboardEventToOptions(eventClone)
              })
            }
          }
        }
      }
      logic.methods.$onPressKey.call(this, e)
    },
  },
}
