import Vue from 'vue'
import { isCask, isBasket, isBottle } from '~/utils/product-item-type'

const plugin = {
  install() {
    Vue.mixin({
      methods: {
        $isBasket: isBasket,
        $isBottle: isBottle,
        $isCask: isCask,
      },
    })
  },
}

Vue.use(plugin)
